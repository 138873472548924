import _ from "lodash";
import { Component, inject, Injectable, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { asyncScheduler, BehaviorSubject, combineLatest, firstValueFrom, Observable, of, scheduled, takeWhile } from "rxjs";
import { catchError } from "rxjs/operators";
import { mixins } from "@logex/mixin-flavors";

import { getDialogFactoryBase, IDialogComponent, LgDialogFactory, LgDialogRef, LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";
import { IColumnFilterDictionary, IFilterOption } from "@logex/framework/types";
import { LoaderArgumentsMap, LoadManager, MaybeStaleData, STALE_DATA_SERVICE, useStaleData } from "@logex/load-manager";
import { DialogMixin, HandleErrorsMixin } from "@logex/mixins";

import { AppDefinitions } from "@shared";

import { TracingLink, TracingLinkLeft, TracingLinkRight, TracingNode } from "./components/tracing-chart/tracing-chart.types";
import { TracingDialogGateway } from "./gateways/tracing-dialog-gateway";
import { NodeInfo, RelatedNodeInfo, Schema, SchemaField, SelectNodeArguments, SelectRelatedNodesArguments } from "./gateways/tracing-dialog-gateway.types";
import { Column, DataSets, TracingColumnEnum, TracingNodeDimension, TracingNodeDimensionValue, TracingNodeExt } from "./tracing-dialog.types";
import { TracingChartComponent } from "./components/tracing-chart/tracing-chart.component";
import { AppFeatures, TracingFeatureConfig } from "@shared/app-features";
import { TracingDialogExport } from "@shared/dialogs/tracing-dialog/services/tracing-dialog.export";
import { LG_FEATURES } from "@logex/framework/lg-application";


// ----------------------------------------------------------------------------------
export interface TracingDialogArguments {
    [K: string]: string | number;

    clientId: number;
    scenarioId: number;
    column: TracingColumnEnum;
}


type MaybeStaleDataWithArgs<TData, TArgs = any[]> = MaybeStaleData<TData> & { args: TArgs; };


export interface TracingDialogComponent extends DialogMixin<TracingDialogComponent>,
    HandleErrorsMixin {
}

@Component({
    selector: "app-tracing-dialog",
    templateUrl: "./tracing-dialog.component.tshtml",
    providers: [
        ...useTranslationNamespace("APP._TracingDialog"),
        LoadManager,
        useStaleData(),
    ],
    standalone: false
})
@mixins(DialogMixin, HandleErrorsMixin)
export class TracingDialogComponent implements IDialogComponent<TracingDialogComponent>, OnDestroy {

    _promptDialog = inject(LgPromptDialog);
    _dialogRef = inject(LgDialogRef<TracingDialogComponent>);
    _lgTranslate = inject(LgTranslateService);
    protected _definitions = inject(AppDefinitions);
    private _gateway = inject(TracingDialogGateway);
    private _lgConsole = inject(LgConsole);
    private _loadManager = inject(LoadManager, { self: true });
    private _staleDataService = inject(STALE_DATA_SERVICE, { self: true });
    private _tracingDialogExport = inject(TracingDialogExport);

    readonly nodesGroupingLimit = 10;

    private _features = inject(LG_FEATURES);

    constructor() {
        this._initMixins();
    }

    ngOnDestroy(): void {
        this._columns.forEach(x => x.options.complete());
    }


    // ----------------------------------------------------------------------------------
    // Dialog configuration
    _dialogClass = "lg-dialog lg-dialog--7col";
    _title = this._lgTranslate.translate(".DialogTitle");


    // ----------------------------------------------------------------------------------
    private _args: TracingDialogArguments;
    private _loadingSubj = new BehaviorSubject(true);
    private _isStaleSubj = new BehaviorSubject(false);

    private _schema: Schema;
    private _schemaFieldsLookup: _.Dictionary<SchemaField>;
    private _firstColumn: number;
    private _lastColumn: number;

    _data: TracingNodeExt[];
    _centralNode: TracingNodeExt;
    _columns: Column[];
    _loading$: Observable<boolean> = this._loadingSubj.asObservable();
    _isStale$: Observable<boolean> = this._isStaleSubj.asObservable();

    @ViewChild(TracingChartComponent, { static: true }) private _tracingChart: TracingChartComponent;
    @ViewChild("headerTemplate", { static: true }) _dialogHeaderTemplate: TemplateRef<any>;


    // ----------------------------------------------------------------------------------
    async show(args: TracingDialogArguments): Promise<void> {
        this._args = args;

        this._addPreloaders();
        await this._preload();
        this._configureColumns();

        this._addLoaders();

        await this._loadCentralNode(
            _.filter(_.map(args, (value, name) =>
                this._schemaFieldsLookup[name] != null ? { dimension: name, value } : null)),
            this._args.column
        );
    }


    private _addPreloaders(): void {
        // Fields and columns schema loader
        this._loadManager.addLoader(DataSets.SCHEMA, {
            args: [{
                clientId: () => this._args.clientId,
                scenarioId: () => this._args.scenarioId,
            }],
            loader: args => this._gateway
                .selectSchema(args)
        });
    }


    private async _preload(): Promise<void> {
        this._loadingSubj.next(true);
        try {
            this._schema = _.first((await firstValueFrom(this._loadManager.load(DataSets.SCHEMA))));
            this._schemaFieldsLookup = _.keyBy(this._schema.fields, x => x.field);

            const definitions: any[] = _.filter(_.map(this._schema.fields, x => x.type), x => x !== "string");
            await firstValueFrom(this._definitions.load(...definitions));

        } catch (err: any) {
            this._onException(err);
            throw err;

        } finally {
            this._loadingSubj.next(false);
        }
    }


    private _configureColumns(): void {
        const columns = _.map(this._schema.columns, schemaColumn => {
            const blockedBy = this._convertFieldsBlockedBy(schemaColumn.fieldsBlockedBy);
            const feature = this._features.getFeature<TracingFeatureConfig>(AppFeatures.TRACING);
            const defaultGroupBy = feature.configuration.defaultGroupBy[schemaColumn.column];

            // Select only first dimension if there are blockers to prevent error
            const selectedDimensions = defaultGroupBy || (schemaColumn.fieldsBlockedBy == null ? schemaColumn.groupBy : [schemaColumn.groupBy[0]]);

            const options = this._dimensionsToFilterOptions(schemaColumn.groupBy, selectedDimensions, blockedBy);
            const selectedOptions = this._filterOptionsToColumnFilterDictionary(selectedDimensions, options);

            return {
                column: schemaColumn.column,
                titleLc: `APP._TracingDialog.Columns.${schemaColumn.column}`,
                allDimensions: schemaColumn.groupBy,
                selectedDimensions,
                blockedBy,
                options: new BehaviorSubject<IFilterOption[]>(options),
                selectedOptions,
                activeNode: null,
                isCentral: false,
            } as Column;
        });

        // Store columns into array using `column` as index
        this._columns = [];
        for (const x of columns) {
            this._columns[x.column] = x;
        }

        this._firstColumn = 0;
        this._lastColumn = this._columns.length - 1;
    }


    private _addLoaders(): void {
        // Add separate loader for each column to support stale data
        for (const schemaColumn of this._schema.columns) {
            const columnIdx = schemaColumn.column;
            const columnLoaders = {
                [`${DataSets.CENTRAL}_${columnIdx}`]: this._gateway.selectNode.bind(this._gateway),
                [`${DataSets.FORWARD}_${columnIdx}`]: this._gateway.selectForwardNodes.bind(this._gateway),
                [`${DataSets.BACKWARD}_${columnIdx}`]: this._gateway.selectBackwardNodes.bind(this._gateway),
            };

            const columnDatasetsIsStale = {};

            _.each(columnLoaders, (loader, datasetName) => {
                this._loadManager.add(
                    this._staleDataService.configureLoader(
                        datasetName, {
                            args: [{
                                column: () => columnIdx,
                                clientId: () => this._args.clientId,
                                scenarioId: () => this._args.scenarioId,
                            }],
                            storeResult: true,
                            loader: (args, subscription) => loader(args, subscription)
                        },
                        <TData>(data: TData, isStale: boolean, args: any[]) =>
                            ({ data, isStale, args } as MaybeStaleDataWithArgs<TData>)
                    )
                );
            });
        }
    }


    /***
     * Convert pairs of mutually exclusive fields into dimension blocks map
     *
     * @param fieldsBlockedBy
     * @private
     */
    private _convertFieldsBlockedBy(fieldsBlockedBy: string[][]): Map<string, string[]> {
        const res = new Map<string, string[]>();
        if (fieldsBlockedBy != null) {
            for (const fieldsPair of fieldsBlockedBy) {
                const [f1, f2] = fieldsPair;

                let c1 = res.get(f1);
                if (c1 == null) {
                    c1 = [];
                    res.set(f1, c1);
                }
                c1.push(f2);

                let c2 = res.get(f2);
                if (c2 == null) {
                    c2 = [];
                    res.set(f2, c2);
                }
                c2.push(f1);
            }
        }
        return res;
    }


    private _dimensionsToFilterOptions(allDimensions: string[], selectedDimensions: string[], blockers: Map<string, string[]>): IFilterOption[] {
        // Disable dimensions that are not available with current choices
        const disabledDimensions: string[] = [];
        allDimensions.forEach(dimension => {
            const isDisabled = blockers.get(dimension)?.some(blockingDimension =>
                selectedDimensions.includes(blockingDimension));
            if (isDisabled) {
                disabledDimensions.push(dimension);
            }
        });

        return _.map(allDimensions, dimension => {
            const schemaField = this._schemaFieldsLookup[dimension];
            if (schemaField == null) throw Error(`Unknown field ${dimension} is referenced from columns schema`);

            const name = this._lgTranslate.translate(schemaField.nameLc);
            const disabled = disabledDimensions.includes(dimension);
            const tooltipBlockerText = this._lgTranslate.translate(".DimensionDisabledTooltip",
                {
                    blockers: blockers
                        .get(dimension)
                        ?.map(blocker => this._lgTranslate.translate(this._schemaFieldsLookup[blocker].nameLc))
                        .join(", ")
                });

            return {
                id: dimension,
                name,
                disabled,
                data: disabled ? `${name}<br><br>${tooltipBlockerText}` : null
            };
        });
    }


    private _filterOptionsToColumnFilterDictionary(selectedDimensions: string[], allOptions: IFilterOption[]): IColumnFilterDictionary {
        if (_.isEmpty(allOptions)) return { $empty: true };

        return _.reduce(allOptions, (a, y) => {
            if (_.includes(selectedDimensions, y.id)) {
                a[y.id] = y.name;
            }
            return a;
        }, {} as IColumnFilterDictionary);
    }


    // ----------------------------------------------------------------------------------
    private async _loadCentralNode(dimensions: TracingNodeDimensionValue[], columnIdx: number): Promise<void> {
        const column = this._columns[columnIdx];

        this._loadingSubj.next(true);

        // Cancel all existing data loading subscriptions - loading central node resets everything
        for (const c of this._columns) {
            c.dataSub?.unsubscribe();
            c.dataSub = null;
        }

        // To start with the most bottom level on flexible pages and filter blocked dimensions
        const filteredDimensions: TracingNodeDimensionValue[] = [];
        dimensions.slice().reverse()
            .forEach(newDimension => {
                if (!column.allDimensions.includes(newDimension.dimension)) return;

                const newDimensionBlockers = column.blockedBy.get(newDimension.dimension);

                const isBlocked = newDimensionBlockers !== undefined && filteredDimensions.some(
                    usedDimension => newDimensionBlockers.includes(usedDimension.dimension)
                );

                if (!isBlocked) {
                    filteredDimensions.push(newDimension);
                }
            });
        filteredDimensions.reverse();
        this._loadManager.loadParamsOverride(`${DataSets.CENTRAL}_${columnIdx}`,
            [this._getFiltersArgument(filteredDimensions)]);
        // Start loading central node
        const centralNodeLoader: Observable<MaybeStaleDataWithArgs<NodeInfo[], readonly [SelectNodeArguments]>> =
            this._loadManager.dataAsObservable(`${DataSets.CENTRAL}_${columnIdx}`);

        // Start loading backward node if needed
        let backwardNodesLoader: Observable<MaybeStaleDataWithArgs<RelatedNodeInfo[], [SelectRelatedNodesArguments]>>;
        if (columnIdx > this._firstColumn) {
            this._loadManager.loadParamsOverride(`${DataSets.BACKWARD}_${columnIdx}`,
                [{
                    ...this._getFiltersArgument(filteredDimensions),
                    groupBy: () => this._columns[columnIdx - 1].selectedDimensions,
                }]);
            backwardNodesLoader = this._loadManager.dataAsObservable(`${DataSets.BACKWARD}_${columnIdx}`);
        } else {
            backwardNodesLoader = scheduled(of({ isStale: false, data: null, jobs: null, args: null }), asyncScheduler);
        }

        // Start loading forward node if needed
        let forwardNodesLoader: Observable<MaybeStaleDataWithArgs<RelatedNodeInfo[], [SelectRelatedNodesArguments]>>;
        if (columnIdx < this._lastColumn) {
            this._loadManager.loadParamsOverride(`${DataSets.FORWARD}_${columnIdx}`,
                [{
                    ...this._getFiltersArgument(filteredDimensions),
                    groupBy: () => this._columns[columnIdx + 1].selectedDimensions,
                }]);
            forwardNodesLoader = this._loadManager.dataAsObservable(`${DataSets.FORWARD}_${columnIdx}`);
        } else {
            forwardNodesLoader = scheduled(of({ isStale: false, data: null, jobs: null, args: null }), asyncScheduler);
        }

        column.dataSub = combineLatest([
            centralNodeLoader,
            backwardNodesLoader,
            forwardNodesLoader,
        ])
            .pipe(
                takeWhile(data => _.some(data, x => x.isStale), true),
                catchError((err) => {
                    this._loadingSubj.next(false);
                    if ((err.error?.ExceptionMessage as string)?.includes("Invalid column name")) {
                        this._promptDialog.warning(
                            this._lgTranslate.translate("APP._.Warning"),
                            this._lgTranslate.translate(".WarningUnavailable")
                        );
                        this._close();
                        throw err;
                    }

                    this._onException(err);
                    throw err;
                }),
            )
            .subscribe((data) => {
                column.isStale = _.some(data, x => x.isStale);

                // If data is not stale anymore, stop listening
                if (!column.isStale) {
                    column.dataSub = null;
                }
                this._processLoadCentralNodeResponse(columnIdx, data[0], data[1], data[2]);
                this._syncIsStale();
                this._loadingSubj.next(false);
            });

        // Select loaded dimensions in dropdown
        column.selectedDimensions = filteredDimensions.map(item => item.dimension);
        column.selectedOptions = this._filterOptionsToColumnFilterDictionary(column.selectedDimensions, column.options.value);
        column.options.next(this._dimensionsToFilterOptions(column.allDimensions, column.selectedDimensions, column.blockedBy));
    }


    private _processLoadCentralNodeResponse(
        columnIdx: number,
        central: MaybeStaleDataWithArgs<NodeInfo[], readonly [SelectNodeArguments]>,
        backward: MaybeStaleDataWithArgs<RelatedNodeInfo[], readonly [SelectRelatedNodesArguments]>,
        forward: MaybeStaleDataWithArgs<RelatedNodeInfo[], readonly [SelectRelatedNodesArguments]>
    ) {
        // Central column
        const centralNodeDimensions = _.filter(_.map(central.args[0].filters, (value, name) => {
            if (!_.isEmpty(value)) {
                // Filter has array of values, but we need only one here - thus _.first()
                return this._asTracingNodeDimension(name, _.first(value));
            } else {
                return null;
            }
        }));

        const centralNode: TracingNodeExt = {
            dimensions: centralNodeDimensions,
            cost: _.first(central.data)?.cost ?? 0,
            incomingCost: 0,
            outgoingCost: 0,
            column: columnIdx,
            order: 0,
            clickable: this._isColumnClickable(columnIdx),
            central: true,
            loaded: true,
        };

        const nodes: TracingNodeExt[] = [];
        nodes.push(centralNode);

        // Left column
        nodes.push(...this._processLoadedNodesBackwards(backward, centralNode));

        // Right column
        nodes.push(...this._processLoadedNodesForward(forward, centralNode));

        this._data = nodes;
        this._centralNode = centralNode;

        // Adjust active nodes per column
        _.each(this._columns, x => {
            x.activeNode = null;
            x.isCentral = false;
        });
        const column = this._columns[centralNode.column];
        column.activeNode = centralNode;
        column.isCentral = true;
    }


    private _asTracingNodeDimension(dimension, value): TracingNodeDimension {
        const def = this._schemaFieldsLookup[dimension];
        if (def == null) throw Error(`Filters refer to unknown dimension ${dimension}`);

        return {
            dimension,
            value,
            type: def.type,
            nameLc: def.nameLc,
        };
    }


    private async _loadNodesBackwards(node: TracingNodeExt): Promise<void> {
        return this._loadAdditionalNodes(
            node,
            `${DataSets.BACKWARD}_${node.column}`,
            {
                ...this._getFiltersArgument(node.dimensions),
                groupBy: () => this._columns[node.column - 1].selectedDimensions,
            },
            false
        );
    }


    private async _loadNodesForward(node: TracingNodeExt): Promise<void> {
        await this._loadAdditionalNodes(
            node,
            `${DataSets.FORWARD}_${node.column}`,
            {
                ...this._getFiltersArgument(node.dimensions),
                groupBy: () => this._columns[node.column + 1].selectedDimensions,
            },
            true
        );

        setTimeout(() => {
            this._tracingChart.scrollToRightColumn();
        }, 10);
    }


    private _getFiltersArgument(dimensions: TracingNodeDimensionValue[]): LoaderArgumentsMap {
        return {
            filters: () =>
                _.reduce(dimensions, (a, x) => {
                    a[x.dimension] = [x.value];
                    return a;
                }, {})
        };
    }


    private _loadAdditionalNodes(
        node: TracingNodeExt,
        loader: string,
        args: LoaderArgumentsMap,
        goingForward: boolean
    ): Promise<void> {

        this._loadingSubj.next(true);

        this._loadManager.loadParamsOverride(loader, [args]);
        const dataLoader: Observable<MaybeStaleDataWithArgs<RelatedNodeInfo[], [SelectRelatedNodesArguments]>> =
            this._loadManager.dataAsObservable(loader);

        return new Promise(resolve => {
            const column = this._columns[node.column];
            column.dataSub = dataLoader
                .pipe(
                    takeWhile(data => data.isStale, true),
                    catchError(err => {
                        this._loadingSubj.next(false);
                        this._onException(err);
                        throw err;
                    }),
                )
                .subscribe((data) => {
                    column.isStale = data.isStale;
                    // If data is not stale anymore, stop listening
                    if (!column.isStale) {
                        column.dataSub = null;
                    }

                    // Prepare new nodes for the left
                    const nodes: TracingNodeExt[] = goingForward
                        ? this._processLoadedNodesForward(data, node)
                        : this._processLoadedNodesBackwards(data, node);

                    // Remove all nodes right (left) from the reference node
                    const existingNodes = _.filter(this._data,
                        x => goingForward && x.column <= node.column || !goingForward && x.column >= node.column);

                    // Unmark previously loaded node
                    _.each(
                        _.filter(existingNodes, x => x.column === node.column && x.loaded),
                        x => {
                            x.loaded = false;
                            x.clickable = this._isColumnClickable(node.column);
                        }
                    );

                    // Mark current node
                    node.loaded = true;
                    node.clickable = false;

                    // Make new data array
                    this._data = [...existingNodes, ...nodes];

                    // Clean active nodes and loader subscriptions per columns to the left/right of the loaded node
                    if (!goingForward) {
                        for (let i = 0; i < node.column; i++) {
                            resetColumnState(this._columns[i]);
                        }
                    } else {
                        for (let i = node.column + 1; i < this._columns.length; i++) {
                            resetColumnState(this._columns[i]);
                        }
                    }

                    // Set active node in the loaded column
                    this._columns[node.column].activeNode = node;

                    this._loadingSubj.next(false);
                    this._syncIsStale();

                    // Signal output promise on the first load
                    if (resolve != null) {
                        resolve();
                        resolve = null;
                    }
                });
        });


        // ---
        function resetColumnState(column: Column): void {
            column.activeNode = null;
            column.dataSub?.unsubscribe();
            column.dataSub = null;
            column.isStale = false;
        }
    }


    private _processLoadedNodesBackwards(
        res: MaybeStaleDataWithArgs<RelatedNodeInfo[], readonly [SelectRelatedNodesArguments]>,
        refNode: TracingNodeExt
    ): TracingNodeExt[] {
        if (_.isEmpty(res.data)) return [];

        const groupBy = res.args[0].groupBy;

        refNode.incomingCost = _.sum(_.map(res.data, "linkCost"));

        const sortedData = _.orderBy(res.data, x => Math.abs(x.linkCost), ["desc"]);
        return this._groupNodes(
            _.map(sortedData, x => {
                const dimensions = _.map(groupBy, (name) => {
                    const value = x[name];
                    return this._asTracingNodeDimension(name, value);
                });

                return {
                    dimensions,
                    cost: x.cost,
                    incomingCost: 0, // Left is not loaded yet
                    outgoingCost: x.linkCost,
                    column: refNode.column - 1,
                    links: [{ right: refNode, cost: x.linkCost }],
                    order: 0,
                    clickable: this._isColumnClickable(refNode.column - 1),
                    central: false,
                    loaded: false,
                } as TracingNodeExt;
            })
        );
    }


    private _processLoadedNodesForward(
        res: MaybeStaleDataWithArgs<RelatedNodeInfo[], readonly [SelectRelatedNodesArguments]>,
        refNode: TracingNodeExt
    ): TracingNodeExt[] {
        if (_.isEmpty(res.data)) return [];

        const groupBy = res.args[0].groupBy;

        refNode.outgoingCost = _.sum(_.map(res.data, "linkCost"));

        const sortedData = _.orderBy(res.data, x => Math.abs(x.linkCost), ["desc"]);
        return this._groupNodes(
            _.map(sortedData, x => {
                const dimensions = _.map(groupBy, (name) => {
                    const value = x[name];
                    return this._asTracingNodeDimension(name, value);
                });

                return {
                    dimensions,
                    cost: x.cost,
                    incomingCost: x.linkCost,
                    outgoingCost: 0, // Right is not loaded yet
                    column: refNode.column + 1,
                    links: [{ left: refNode, cost: x.linkCost }],
                    order: 0,
                    clickable: this._isColumnClickable(refNode.column + 1),
                    central: false,
                    loaded: false,
                } as TracingNodeExt;
            })
        );
    }


    private _groupNodes(nodes: TracingNodeExt[]): TracingNodeExt[] {
        if (nodes.length <= this.nodesGroupingLimit + 1) return nodes;

        const res = _.take(nodes, this.nodesGroupingLimit);
        const group = _.takeRight(nodes, nodes.length - this.nodesGroupingLimit);

        res.push({
            column: group[0].column,
            grouped: group,
            dimensions: null,
            cost: _.sum(_.map(group, "cost")),
            incomingCost: _.sum(_.map(group, "incomingCost")),
            outgoingCost: _.sum(_.map(group, "outgoingCost")),
            links: this._combineLinks(group),
            order: _.max(_.map(nodes, "order")) + 1,
            clickable: true,
            central: false,
            loaded: true,
        });

        return res;
    }


    private _combineLinks(nodes: TracingNode[]): TracingLink[] {
        // Assuming that grouped nodes would all have only one link pointing in one direction and to one target
        const links = _.flatten(_.map(nodes, x => x.links));
        const firstLink = _.first(links);

        const resLink = {
            cost: _.sum(_.map(links, "cost")),
        } as TracingLink;

        if ("left" in firstLink) {
            (<TracingLinkLeft>resLink).left = firstLink.left;
        } else {
            (<TracingLinkRight>resLink).right = firstLink.right;
        }

        return [resLink];
    }


    private _isColumnClickable(column: number): boolean {
        return column > this._firstColumn && column < this._lastColumn;
    }


    async _onNodeClick(node: TracingNodeExt): Promise<void> {
        if (node.grouped != null) {
            // Get top nodes from the group
            const regrouped = this._groupNodes(node.grouped);
            this._data = _.filter(this._data, x => x !== node);
            this._data.push(...regrouped);

            return;
        }

        // Load additional nodes
        if (node.loaded || !this._isColumnClickable(node.column)) return;

        if (node.column > this._centralNode.column) {
            await this._loadNodesForward(node);
        } else {
            await this._loadNodesBackwards(node);
        }
    }


    async _changeCentralNode($event: MouseEvent, node: TracingNodeExt): Promise<void> {
        $event.stopImmediatePropagation();

        await this._loadCentralNode(node.dimensions, node.column);

        setTimeout(() => {
            this._tracingChart.scrollToLeftColumn();
        }, 10);
    }


    async _changeColumnDimensions(columnIdx: number, selectedOptions: IColumnFilterDictionary): Promise<void> {
        const column = this._columns[columnIdx];
        if (selectedOptions.$empty !== true) {
            column.selectedOptions = selectedOptions;
            column.selectedDimensions = _.keys(selectedOptions);
        } else {
            // All options are removed - keep previous options selected
            column.selectedOptions = _.cloneDeep(column.selectedOptions);
            column.options.next(this._dimensionsToFilterOptions(column.allDimensions, column.selectedDimensions, column.blockedBy));
            return;
        }

        // Reload the column
        if (columnIdx < this._centralNode.column) {
            await this._loadNodesBackwards(this._columns[columnIdx + 1].activeNode);
        } else {
            await this._loadNodesForward(this._columns[columnIdx - 1].activeNode);
        }
    }


    _onDimensionsSelectChange(columnIdx: number, selectedOptions: IColumnFilterDictionary) {
        const column = this._columns[columnIdx];
        const selectedDimensions = _.keys(selectedOptions);

        column.options.next(this._dimensionsToFilterOptions(column.allDimensions, selectedDimensions, column.blockedBy));
    }


    private _syncIsStale(): void {
        this._isStaleSubj.next(_.some(this._columns, x => x.isStale));
    }


    _areCostsEqual(c1: number, c2: number): boolean {
        return Math.abs(c1 - c2) < 1e-8;
    }

    _exportToExcel(): void {
        this._tracingDialogExport.export(this._columns, this._data);
    }
}


@Injectable()
export class TracingDialog extends getDialogFactoryBase(TracingDialogComponent, "show") {
    constructor() {
        const _factory = inject(LgDialogFactory);

        super(_factory);
    }
}
