import { Component, inject, Input, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { LoadManager, useStaleData } from "@logex/load-manager";
import { RulesPivot } from "../base/pivots/rules-pivot.service";
import { AccountTransferRulesGateway } from "./gateways/account-transfer-rules-gateway";
import { TransferRuleSectionComponentBase } from "../base/transfer-rule-section-component-base";
import { RuleSection } from "../../types/constants";
import { RuleEditorComponentBase } from "@shared/dialogs/rules-dialog/components/base/rule-editor-component-base";
import { LgEditableGridTextComponent } from "@logex/framework/ui-core";

@Component({
    selector: "mod-account-transfer-rules",
    templateUrl: "./account-transfer.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.AccountTransfer"),
        LoadManager,
        useStaleData(),
        RulesPivot,
        AccountTransferRulesGateway,
    ],
    standalone: false
})
export class AccountTransferRulesSectionComponent extends TransferRuleSectionComponentBase {

    _gateway = inject(AccountTransferRulesGateway);

    @Input() isReadonly = false;

    _currentSection = RuleSection.AccountTransfer;

    @ViewChild("editor") protected _editor: RuleEditorComponentBase;
    @ViewChildren("groupName") groupNames: QueryList<LgEditableGridTextComponent>;

}
