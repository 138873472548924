import { Component, inject, Input, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { RuleSectionComponentBase } from "../base/rule-section-component-base";
import { RulesPivot } from "../base/pivots/rules-pivot.service";
import { RulesPivotLevel2Ext } from "./pivots/resource-specification-pivot.types";
import { LoadManager, useStaleData } from "@logex/load-manager";
import { ResourceSpecificationRulesGateway } from "./gateways/resource-specification-rules-gateway.service";
import { IAppDefinitions } from "@shared";
import { ResourceManagementDialog } from "@shared/dialogs/resource-management-dialog/resource-management-dialog.component";
import { RuleSection } from "../../types/constants";
import { RuleEditableFieldsExt } from "./gateways/resource-specification-rules-gateway.types";
import { RuleEditorComponentBase } from "@shared/dialogs/rules-dialog/components/base/rule-editor-component-base";
import { LgEditableGridTextComponent } from "@logex/framework/ui-core";


@Component({
    selector: "mod-resource-specification-rules",
    templateUrl: "./resource-specification.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.ResourceSpecification"),
        LoadManager,
        useStaleData(),
        RulesPivot,
        ResourceSpecificationRulesGateway,
    ],
    standalone: false
})
export class ResourceSpecificationRulesSectionComponent extends RuleSectionComponentBase<RulesPivotLevel2Ext> {

    private _resourceManagementDialog = inject(ResourceManagementDialog);
    _gateway = inject(ResourceSpecificationRulesGateway);

    @Input() isReadonly = false;

    @ViewChild("editor") protected _editor: RuleEditorComponentBase;
    @ViewChildren("groupName") groupNames: QueryList<LgEditableGridTextComponent>;

    _currentSection = RuleSection.ResourceSpecification;

    
    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["resourceType", "subAccountSourceType"];


    protected _newRule(groupId: number, position: number) {
        return {
            groupId,
            position,
            isEnabled: true,
            name: "",
        } as RulesPivotLevel2Ext;
    }

    protected _getRulePropsForSaving(rule: RuleEditableFieldsExt): RuleEditableFieldsExt {
        return {
            ...super._getRulePropsForSaving(rule),
            resourceTypeCode: rule.resourceTypeCode,
        }
    }

    _openResourceManager(): void {
        this._resourceManagementDialog
            .show({
                isReadonly: this.isReadonly,
            });
    }
}
