import _ from "lodash";
import * as d3 from "d3";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, TemplateRef, inject } from "@angular/core";

import { LgSimpleChanges } from "@logex/framework/types";
import { ColumnInternal, LinkInternal, NodeInternal, TracingNode } from "./tracing-chart.types";
import { atNextFrame } from "@logex/framework/utilities";

// ----------------------------------------------------------------------------------

@Component({
    selector: "mod-tracing-chart",
    templateUrl: "./tracing-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "mod-tracing-chart",
        "[style.width.px]": "width",
        "[style.height.px]": "height",
    },
    standalone: false
})
export class TracingChartComponent implements OnChanges {

    private _changeDetector = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef<HTMLElement>);

    @Input() width: number;
    @Input() height: number;
    @Input() data: TracingNode[];
    @Input() nodeTemplate: TemplateRef<void>;
    @Input() columnTitleTemplate: TemplateRef<void>;
    @Output() readonly nodeClick: EventEmitter<TracingNode> = new EventEmitter<TracingNode>();

    // ----------------------------------------------------------------------------------
    private static nodesCount = 0;

    _columns: ColumnInternal[];

    private _idByDataNode: Map<TracingNode, string> = new Map<TracingNode, string>();
    private _nodes: Map<string, NodeInternal> = new Map<string, NodeInternal>();
    private _columnsScrollTop: _.Dictionary<number>;

    // ----------------------------------------------------------------------------------

    ngOnChanges(changes: LgSimpleChanges<TracingChartComponent>): void {
        if (changes.data) {
            this._saveScrollPositions();
            this._prepareData();
        }

        if (changes.height || changes.width || changes.data) {
            atNextFrame(() => {
                this._draw();

                if (changes.data) {
                    this._restoreScrollPositions();
                }
            });
        }
    }

    public scrollToLeftColumn(): void {
        const firstColumn = this._elementRef.nativeElement.querySelector(".mod-tracing-chart__column");
        if (firstColumn == null) return;
        firstColumn.scrollIntoView({ behavior: "auto", inline: "end" });
    }

    public scrollToRightColumn(): void {
        const lastColumn = this._elementRef.nativeElement.querySelector(".mod-tracing-chart__column:last-child");
        if (lastColumn == null) return;
        lastColumn.scrollIntoView({ behavior: "auto", inline: "end" });
    }

    private _prepareData(): void {
        // Index all incoming nodes, if they are not indexed yet. Find max cost. Put them into columns
        let maxCost = 0;
        let centralColumn = 0;
        const columnsMap: _.Dictionary<{ column: number; nodes: TracingNode[] }> = {};
        _.each(this.data, (node) => {
            // If this is a new node
            if (!this._idByDataNode.has(node)) {
                this._idByDataNode.set(node, this._makeNodeId());
            }

            // Max cost
            maxCost = Math.max(maxCost, Math.abs(node.cost));
            _.each(node.links, (link) => {
                maxCost = Math.max(maxCost, Math.abs(link.cost));
            });

            if (node.central) centralColumn = node.column;

            // Arrange in columns
            let column = columnsMap[node.column];

            if (column == null) {
                column = { column: node.column, nodes: [] };
                columnsMap[node.column] = column;
            }

            column.nodes.push(node);
        });

        // Convert nodes to NodeInternal
        const deletedNodes = new Set(this._nodes.values());
        this._columns = _.map(_.sortBy(columnsMap, "column"), (column) => ({
            column: column.column,
            nodes: _.map(column.nodes, (dataNode) => {
                const nodeId = this._idByDataNode.get(dataNode);
                let nodeInt = this._nodes.get(nodeId);

                if (nodeInt == null) {
                    nodeInt = {
                        id: nodeId,
                        dataNode,
                        linksLeft: [],
                        linksRight: [],
                        cost: adjustCost(dataNode.cost),
                        costLeftNegative: 0,
                        costLeftPositive: 0,
                        costLeft: 0,
                        costRightNegative: 0,
                        costRightPositive: 0,
                        costRight: 0,
                    };
                    this._nodes.set(nodeInt.id, nodeInt);
                } else {
                    deletedNodes.delete(nodeInt);
                }

                return nodeInt;
            }),
        }));

        // Remove deleted nodes
        deletedNodes.forEach((node) => {
            this._nodes.delete(node.id);

            // Remove links to the node from left and right target nodes. Remove SVG element for the link.
            _.each(node.linksLeft, (l) => {
                l.left.linksRight = _.filter(l.left.linksRight, (x) => x.right !== node);
                if (l.svgLink != null) l.svgLink.remove();
            });
            _.each(node.linksRight, (l) => {
                l.right.linksLeft = _.filter(l.right.linksLeft, (x) => x.left !== node);
                if (l.svgLink != null) l.svgLink.remove();
            });
        });

        // Remap links
        this._nodes.forEach((node) => {
            _.each(node.dataNode.links, (dataLink) => {
                let link: LinkInternal;
                if ("left" in dataLink) {
                    const leftNode = this._nodes.get(this._idByDataNode.get(dataLink.left));
                    link = this._findOrAddLink(leftNode, node);
                } else {
                    const rightNode = this._nodes.get(this._idByDataNode.get(dataLink.right));
                    link = this._findOrAddLink(node, rightNode);
                }
                link.cost = adjustCost(dataLink.cost);
            });
            node.linksLeft = _.orderBy(node.linksLeft, ["cost"], ["desc"]);
            node.linksRight = _.orderBy(node.linksRight, ["cost"], ["desc"]);
        });

        // Sum link costs
        this._nodes.forEach((node) => {
            const [costLeftPositive, costLeftNegative] = processLinksCost(node.linksLeft, true);
            const [costRightPositive, costRightNegative] = processLinksCost(node.linksRight, false);

            node.costLeftPositive = costLeftPositive;
            node.costLeftNegative = costLeftNegative;
            node.costLeft = costLeftNegative + costLeftPositive;

            node.costRightPositive = costRightPositive;
            node.costRightNegative = costRightNegative;
            node.costRight = costRightNegative + costRightPositive;
        });

        // Sort nodes in columns by link's absolute cost
        _.each(this._columns, (column) => {
            if (column.column < centralColumn) {
                column.nodes = _.orderBy(column.nodes, ["dataNode.order", (x) => Math.abs(x.costRight)], ["asc", "desc"]);
            } else {
                column.nodes = _.orderBy(column.nodes, ["dataNode.order", (x) => Math.abs(x.costLeft)], ["asc", "desc"]);
            }
        });

        // ---
        function adjustCost(cost: number): number {
            if (maxCost === 0) return 0;
            return (cost / maxCost) * 100;
        }

        function processLinksCost(links: LinkInternal[], isLeft: boolean): [number, number] {
            let positive = 0;
            let negative = 0;
            for (let i = 0; i < links.length; i++) {
                const link = links[i];
                const cost = link.cost;
                if (cost >= 0) {
                    if (isLeft) {
                        link.rightOffset = positive;
                    } else {
                        link.leftOffset = positive;
                    }
                    positive += cost;
                } else {
                    if (isLeft) {
                        link.rightOffset = -negative;
                    } else {
                        link.leftOffset = -negative;
                    }
                    negative += cost;
                }
            }
            return [positive, negative];
        }
    }

    private _makeNodeId() {
        return `mod-tracing-chart-node-${TracingChartComponent.nodesCount++}`;
    }

    private _findOrAddLink(left: NodeInternal, right: NodeInternal): LinkInternal {
        let link = _.find(left.linksRight, (x) => x.right === right);

        if (link == null) {
            link = { left, right } as LinkInternal;
            left.linksRight.push(link);
            right.linksLeft.push(link);
        }

        return link;
    }

    private _selectNodesContainers(): NodeListOf<Element> {
        return this._elementRef.nativeElement.querySelectorAll(".mod-tracing-chart-column__body .lg-scrollable__holder");
    }

    private _saveScrollPositions(): void {
        this._columnsScrollTop = {};

        if (!_.isEmpty(this._columns)) {
            const containers = this._selectNodesContainers();
            containers.forEach((x, i) => {
                this._columnsScrollTop[this._columns[i].column] = x.scrollTop;
            });
        }
    }

    private _restoreScrollPositions(): void {
        if (!_.isEmpty(this._columns)) {
            const containers = this._selectNodesContainers();
            containers.forEach((x, i) => {
                x.scrollTop = this._columnsScrollTop[this._columns[i].column] || 0;
            });
        }
    }

    public _draw(): void {
        const rootElt = this._elementRef.nativeElement;
        const { scrollWidth, clientHeight } = rootElt;

        if (clientHeight === 0 || scrollWidth === 0) return;

        const columnBody = rootElt.querySelector(".mod-tracing-chart-column__body") as HTMLElement;
        if (columnBody == null) return;

        const columnBodyTop = columnBody.offsetTop;

        const svg = d3.select(rootElt).select("svg");

        // Adjust SVG element size
        svg.attr("width", `${scrollWidth}px`).attr("height", `${clientHeight - columnBodyTop}px`);

        // Add link elements or change their coordinates
        this._nodes.forEach((srcNode) => {
            _.each(srcNode.linksRight, (link) => {
                const indicatorPartSelector =
                    link.cost >= 0 ? ".mod-tracing-chart-node__cost-indicator--positive" : ".mod-tracing-chart-node__cost-indicator--negative";

                const srcElt = rootElt.querySelector(`#${srcNode.id} .mod-tracing-chart-node__cost-indicator--right ${indicatorPartSelector}`) as HTMLElement;
                const [srcTop, srcLeft] = getTopLeft(rootElt, srcElt);

                const destElt = rootElt.querySelector(
                    `#${link.right.id} .mod-tracing-chart-node__cost-indicator--left ${indicatorPartSelector}`
                ) as HTMLElement;
                const [destTop, destLeft] = getTopLeft(rootElt, destElt);

                // console.debug( "Link", srcElt, destElt );

                if (link.svgLink == null) {
                    link.svgLink = svg
                        .append("g")
                        .attr("class", `mod-tracing-chart-link ${link.cost >= 0 ? "mod-tracing-chart-link--positive" : "mod-tracing-chart-link--negative"}`)
                        .append("path");
                }

                const strokeWidth = Math.abs(link.cost);
                const halfStrokeWidth = strokeWidth / 2;

                link.svgLink
                    .attr(
                        "d",
                        d3
                            .linkHorizontal()
                            .source(() => [srcLeft + srcElt.offsetWidth, srcTop + link.leftOffset + halfStrokeWidth - columnBodyTop])
                            .target(() => [destLeft, destTop + link.rightOffset + halfStrokeWidth - columnBodyTop])
                    )
                    .attr("stroke-width", Math.max(strokeWidth, 1));
            });
        });

        // ---
        function getTopLeft(root: HTMLElement, child: HTMLElement): [number, number] {
            let top = 0;
            let left = 0;
            let current = child;
            while (current !== root) {
                top += current.offsetTop - current.scrollTop;
                left += current.offsetLeft - current.scrollLeft;
                current = current.offsetParent as HTMLElement;
            }

            return [top, left];
        }
    }

    _onNodeClick(node: NodeInternal): void {
        this.nodeClick.emit(node.dataNode);
    }
}
