import { Component, inject, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { RuleFilterSelectorService } from "@shared/services/rule-filter-selector/rule-filter-selector.service";
import { LoadManager } from "@logex/load-manager";
import { SOURCE_RULE_FILTER_SELECTOR, TARGET_RULE_FILTER_SELECTOR } from "../base/types";
import { TransferRuleEditorComponentBase } from "../base/transfer-rule-editor-component-base";
import { TaskSpecificationRulesGateway } from "./gateways/task-specification-rules-gateway";
import { TransferCostDriverInfo } from "../base/gateway/transfer-rule-gateway.type";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

@Component({
    selector: "mod-task-specification-rules-editor",
    templateUrl: "./rule-editor.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.TaskSpecification"),
        { provide: SOURCE_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        { provide: TARGET_RULE_FILTER_SELECTOR, useClass: RuleFilterSelectorService },
        LoadManager,
        TaskSpecificationRulesGateway,
    ],
    standalone: false
})
export class TaskSpecificationRulesEditorComponent extends TransferRuleEditorComponentBase implements OnInit {

    protected _gateway = inject(TaskSpecificationRulesGateway);

    @Input() id: number;
    @Input() isReadonly: boolean;
    @Input() name: string;
    @Input() costDrivers: TransferCostDriverInfo[];
    @Input() costDriverId: number;
    @Input() sourceSelectorConfig: RuleFilterInfo[];
    @Input() filters: Record<string, unknown[]>;
    @Input() targetFilters: Record<string, unknown[]>;
    @Input() targetSelectorConfig: RuleFilterInfo[];

    _hasAllowedTargets(): boolean {
        return true;
    }
    
    _isTargetAllowed(field: string): boolean {
        return true;
    }

}
