import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";

import { RoleGuardService as RoleGuard } from "@logex/framework/lg-application";

import { BootGuardService as BootGuard } from "@shared/services/boot-guard.service";

// import { LoginComponent } from "./pages/login/login.component";
import { AuthorizedComponent } from "./authorized.component";

const routes: Route[] =
    [
        {
            path: "",
            redirectTo: "dashboard",
            pathMatch: "full",
        },

        {
            path: "boot",
            loadChildren: () => import( "./pages/boot/boot-pages.module" ).then(m => m.BootPagesModule),
        },

        {
            path: "dashboard",
            component: AuthorizedComponent,
            canActivate: [BootGuard, RoleGuard],
            canActivateChild: [BootGuard, RoleGuard],
            loadChildren: () => import( "./pages/dashboard/dashboard.module" ).then(m => m.DashboardModule),
        },

        {
            path: "data",
            component: AuthorizedComponent,
            canActivate: [BootGuard, RoleGuard],
            canActivateChild: [BootGuard, RoleGuard],
            children: [
                {
                    path: "source-data",
                    loadChildren: () => import( "./pages/source-data/source-data.module" ).then(m => m.SourceDataModule),
                },
                {
                    path: "characteristics",
                    loadChildren: () => import( "./pages/characteristics/characteristics.module" ).then(m => m.CharacteristicsModule),
                },
            ]
        },

        {
            path: "modeling",
            component: AuthorizedComponent,
            canActivate: [BootGuard, RoleGuard],
            canActivateChild: [BootGuard, RoleGuard],
            children: [
                {
                    path: "data-preparation",
                    canActivate: [BootGuard, RoleGuard],
                    canActivateChild: [BootGuard, RoleGuard],
                    children: [
                        {
                            path: "organisation-cost",
                            loadChildren: () => import( "./pages/cost-centre-pages/organisation-cost/organisation-cost.module" ).then(
                                m => m.OrganisationCostModule
                            ),
                        },
                        {
                            path: "optimize-data-analytics",
                            loadChildren: () => import( "./pages/flexible-pages/optimize-data-analytics/optimize-data-analytics.module" )
                                .then(m => m.OptimizeDataAnalyticsModule),
                        },
                    ],
                },
                {
                    path: "allocation-of-cost",
                    canActivate: [BootGuard, RoleGuard],
                    canActivateChild: [BootGuard, RoleGuard],
                    children: [
                        {
                            path: "allocate-to-resource",
                            loadChildren: () => import( "./pages/cost-centre-pages/allocate-to-resource/allocate-to-resource.module" ).then(
                                m => m.AllocateToResourceModule
                            ),
                        },
                        {
                            path: "allocate-support-cost",
                            loadChildren: () => import( "./pages/cost-centre-pages/allocate-support-cost/allocate-support-cost.module" ).then(
                                m => m.AllocateSupportCostModule
                            ),
                        },
                        {
                            path: "allocate-cost-analytics",
                            loadChildren: () => import( "./pages/flexible-pages/allocate-cost-analytics/allocate-cost-analytics.module" )
                                .then(m => m.AllocateCostAnalyticsModule),
                        },
                    ],
                },
                {
                    path: "allocation-to-production",
                    canActivate: [BootGuard, RoleGuard],
                    canActivateChild: [BootGuard, RoleGuard],
                    children: [
                        {
                            path: "allocate-to-production",
                            loadChildren: () => import( "./pages/cost-centre-pages/allocate-to-production/allocate-to-production.module" ).then(
                                m => m.AllocateToProductionModule
                            ),
                        },
                        {
                            path: "production-values",
                            loadChildren: () => import( "./pages/production-values/production-values.module" ).then(m => m.ProductionValuesModule),
                        },
                        {
                            path: "allocate-to-production-analytics",
                            loadChildren: () => import( "./pages/flexible-pages/allocate-to-production-analytics/allocate-to-production-analytics.module" )
                                .then(m => m.AllocateToProductionAnalyticsModule),
                        },
                    ],
                },
            ],
        },

        {
            path: "analytics",
            component: AuthorizedComponent,
            canActivate: [BootGuard, RoleGuard],
            canActivateChild: [BootGuard, RoleGuard],
            children: [
                {
                    path: "activity",
                    loadChildren: () => import( "./pages/flexible-pages/activity-analytics/activity-analytics.module" ).then(
                        m => m.ActivityAnalyticsModule
                    ),
                },
                {
                    path: "product",
                    loadChildren: () => import( "./pages/flexible-pages/product-analytics/product-analytics.module" ).then(
                        m => m.ProductAnalyticsModule
                    ),
                },
                {
                    path: "encounter",
                    loadChildren: () => import( "./pages/flexible-pages/encounter-analytics/encounter-analytics.module" ).then(
                        m => m.EncounterAnalyticsModule
                    ),
                }
            ]
        },

        {
            path: "settings",
            component: AuthorizedComponent,
            canActivate: [BootGuard, RoleGuard],
            canActivateChild: [BootGuard, RoleGuard],
            loadChildren: () => import("./pages/settings/settings.module").then(m => m.SettingsModule),
        },

        {
            path: "settings/calculation-service",
            component: AuthorizedComponent,
            canActivate: [BootGuard, RoleGuard],
            canActivateChild: [BootGuard, RoleGuard],
            loadChildren: () => import("@logex/fadp").then(m => m.CALCULATION_SERVICE_ROUTES)
        },

        {
            path: "exports",
            component: AuthorizedComponent,
            canActivate: [BootGuard, RoleGuard],
            canActivateChild: [BootGuard, RoleGuard],
            loadChildren: () => import("@logex/fadp").then(m => m.ExportsModule)
        },

        {
            path: "source-data",
            component: AuthorizedComponent,
            canActivate: [BootGuard, RoleGuard],
            canActivateChild: [BootGuard, RoleGuard],
            loadChildren: () => import( "./pages/source-data/source-data.module" ).then(m => m.SourceDataModule),
        },

        // Fallback route
        {
            path: "**",
            redirectTo: "/dashboard",
        }
    ];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
        })
    ],
    exports: [
        RouterModule
    ],
})
export class AppRoutingModule {
}
