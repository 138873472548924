import { Component, inject, Injectable, TemplateRef, ViewChild } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    IDropdownGroup,
    LgDialogFactory,
    LgDialogRef,
    LgPromptDialog
} from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LG_APP_SESSION, LG_FEATURES } from "@logex/framework/lg-application";
import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";

import { AppDefinitions, AppSession } from "@shared";
import { RuleSection, rulesMenu } from "./types/constants";
import { RulesDialogAuthorization } from "./services/authorization.service";
import {
    SubaccountSpecificationRulesSectionComponent
} from "./components/1-1-subaccount-specification/subaccount-specification.component";
import { RuleSectionComponentBase } from "./components/base/rule-section-component-base";
import { Observable } from "rxjs";
import { AccountTransferRulesSectionComponent } from "./components/1-2-account-transfer/account-transfer.component";
import {
    ResourceSpecificationRulesSectionComponent
} from "./components/2-1-resource-specification/resource-specification.component";
import { ResourceTransferRulesSectionComponent } from "./components/2-2-resource-transfer/resource-transfer.component";
import {
    TaskSpecificationRulesSectionComponent
} from "./components/3-1-task-specification/task-specification.component";
import {
    SupportCostTransferRulesSectionComponent
} from "./components/3-2-support-cost-transfer/support-cost-transfer.component";
import {
    SubtaskSpecificationRulesSectionComponent
} from "./components/4-1-subtask-specification/subtask-specification.component";
import { MapToProductionRulesSectionComponent } from "./components/4-2-map-to-production/map-to-production.component";
import { AppFeatures } from "@shared/app-features";


export interface RulesDialogDialogArguments {
    section?: RuleSection;
}


export interface RulesDialogDialogResult {
    updatedSections: RuleSection[];
    reload: boolean;
}


export interface RulesDialogComponent extends DialogMixin<RulesDialogComponent>,
    ModalResultDialogMixin<RulesDialogDialogArguments, RulesDialogDialogResult> {
}


@Component({
    selector: "app-rules-dialog",
    templateUrl: "./rules-dialog.component.tshtml",
    providers: [
        ...useTranslationNamespace("APP._RulesDialog"),
        RulesDialogAuthorization,
    ],
    standalone: false
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class RulesDialogComponent implements IDialogComponent<RulesDialogComponent> {

    _promptDialog = inject(LgPromptDialog);
    _definitions = inject(AppDefinitions);
    _dialogRef = inject(LgDialogRef<RulesDialogComponent>);
    _lgTranslate = inject(LgTranslateService);
    private _authorization = inject(RulesDialogAuthorization);
    private _session = inject<AppSession>(LG_APP_SESSION);
    private _features = inject(LG_FEATURES);

    constructor() {
        this._initMixins();

        this._isReadonly = !this._authorization.edit || this._session.scenario.isLocked || false;
    }

    // ----------------------------------------------------------------------------------
    //

    // Dialog configuration

    _dialogClass = "lg-dialog lg-dialog--7col";
    _title = this._lgTranslate.translate(".DialogTitle");

    @ViewChild("headerTemplate", { static: true }) _dialogHeaderTemplate: TemplateRef<any>;

    @ViewChild(SubaccountSpecificationRulesSectionComponent, { static: true }) private _subaccountSpecificationRulesSectionComponent: SubaccountSpecificationRulesSectionComponent;
    @ViewChild(AccountTransferRulesSectionComponent, { static: true }) private _accountTransferRulesSectionComponent: AccountTransferRulesSectionComponent;
    @ViewChild(ResourceSpecificationRulesSectionComponent, { static: true }) private _resourceSpecificationRulesSectionComponent: ResourceSpecificationRulesSectionComponent;
    @ViewChild(ResourceTransferRulesSectionComponent, { static: true }) private _resourceTransferRulesSectionComponent: ResourceTransferRulesSectionComponent;
    @ViewChild(TaskSpecificationRulesSectionComponent, { static: true }) private _taskSpecificationRulesSectionComponent: TaskSpecificationRulesSectionComponent;
    @ViewChild(SupportCostTransferRulesSectionComponent, { static: true }) private _supportCostTransferRulesSectionComponent: SupportCostTransferRulesSectionComponent;
    @ViewChild(SubtaskSpecificationRulesSectionComponent, { static: true }) private _subtaskSpecificationRulesSectionComponent: SubtaskSpecificationRulesSectionComponent;
    @ViewChild(MapToProductionRulesSectionComponent, { static: true }) private _mapToProductionRulesSectionComponent: MapToProductionRulesSectionComponent;


    // Fields
    private _currentRule: RuleSectionComponentBase;
    private _updatedSections: Set<RuleSection> = new Set<RuleSection>();

    _isReadonly: boolean;

    _currentSection: RuleSection;
    _currentSectionName: string;

    _navigationDropdown: IDropdownDefinition<any> = {
        entryId: "id",
        entryName: "name",
        groupId: "groupId",
        groupName: "groupName",
        groups: Object.keys(rulesMenu).map(section => (
            {
                groupId: section,
                groupName: this._lgTranslate.translate(`.Section.${section}`),
                entries: [
                    ...rulesMenu[section]
                        .map(entry => {
                            if (entry === RuleSection.SubtaskSpecification && !this._features.isFeatureEnabled(AppFeatures.SUBTASKS)) {
                                return null;
                            }

                            return {
                                id: entry,
                                name: this._lgTranslate.translate(`APP._RulesDialog.Section.${RuleSection[entry]}`),
                            };
                        })
                        .filter(x => x != null)
                ]
            }
        ) as IDropdownGroup)
    };


    // ----------------------------------------------------------------------------------
    //
    async _activate(): Promise<void> {
        this._initialized = true; // Set earlier so that we can switch tabs
        this._onSectionChange(null, this._args.section != null ? this._args.section : RuleSection.SubaccountSpecification);
    }


    _isLoading(): boolean {
        return !this._initialized
            || !this._currentRule?.isActivated
            || this._currentRule?.isLoading;
    }


    get _isStale$(): Observable<boolean> {
        return this._currentRule?.isStale$;
    }

    _sectionName(group: string): string {
        return `
        ${this._lgTranslate.translate(`.Section.${group}`)}
         - 
         ${this._lgTranslate.translate(`.Section.${RuleSection[this._currentSection]}`)}
         `;
    }


    _onSectionChange(from: RuleSection, to: RuleSection): void {
        if (this._currentRule?.isModified()) {
            this._updatedSections.add(from);
        }

        this._currentSection = to;

        if (to === RuleSection.SubaccountSpecification) {
            this._currentRule = this._subaccountSpecificationRulesSectionComponent;
            this._currentSectionName = this._sectionName("OrganisationCosts");
        } else if (to === RuleSection.AccountTransfer) {
            this._currentRule = this._accountTransferRulesSectionComponent;
            this._currentSectionName = this._sectionName("OrganisationCosts");

        } else if (to === RuleSection.ResourceSpecification) {
            this._currentRule = this._resourceSpecificationRulesSectionComponent;
            this._currentSectionName = this._sectionName("AllocateToResource");

        } else if (to === RuleSection.ResourceTransfer) {
            this._currentRule = this._resourceTransferRulesSectionComponent;
            this._currentSectionName = this._sectionName("AllocateToResource");

        } else if (to === RuleSection.TaskSpecification) {
            this._currentRule = this._taskSpecificationRulesSectionComponent;
            this._currentSectionName = this._sectionName("AllocateSupportCosts");

        } else if (to === RuleSection.SupportCostTransfer) {
            this._currentRule = this._supportCostTransferRulesSectionComponent;
            this._currentSectionName = this._sectionName("AllocateSupportCosts");

        } else if (to === RuleSection.SubtaskSpecification) {
            this._currentRule = this._subtaskSpecificationRulesSectionComponent;
            this._currentSectionName = this._sectionName("AllocateToProduction");

        } else if (to === RuleSection.MapToProduction) {
            this._currentRule = this._mapToProductionRulesSectionComponent;
            this._currentSectionName = this._sectionName("AllocateToProduction");

        }

        this._currentRule.activate();
    }


    _onClose(): void {
        if (this._currentRule?.isModified()) {
            this._updatedSections.add(this._currentSection);
        }

        this._resolve({
            updatedSections: [...this._updatedSections],
            reload: this._updatedSections.size > 0
        });
    }
}


@Injectable()
export class RulesDialog extends getDialogFactoryBase(RulesDialogComponent, "show") {
    constructor() {
        super(inject(LgDialogFactory));
    }
}
