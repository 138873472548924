/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */
import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";
import { RuleFilterOption } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

import {
    DeleteGroupArguments,
    DeleteRuleArguments,
    RuleGroup,
    SaveGroupArguments,
    SaveGroupResult,
    SaveRuleArguments,
    SaveRuleResult,
    SelectCostArguments,
    SelectCostDriverArguments,
    SelectCostResult,
    SelectFiltersDataArguments,
    SelectFiltersSchemaArguments,
    SelectRuleGroupArguments,
    SelectRulesArguments
} from "../../base/gateway/rules-gateway.types";
import { SelectTransferFiltersSchema, TransferCostDriverInfo, TransferRule, TransferRuleEditableFields } from "../../base/gateway/transfer-rule-gateway.type";
import { RulesGateway } from "../../base/gateway/rules-gateway";
import { RuleExt } from "@shared/dialogs/rules-dialog/components/1-1-subaccount-specification/gateway/subaccount-specification-rules-gateway.types";


@Injectable()
export class SubtaskSpecificationRulesGateway extends AppServerGatewayBase implements RulesGateway<TransferRule, TransferCostDriverInfo> {

    selectRules(args: SelectRulesArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<RuleExt[]>("rules/resourceTaskSplit", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectRuleGroups(args: SelectRuleGroupArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<RuleGroup[]>("rules/resourceTaskSplit/groups", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectCostDrivers(args: SelectCostDriverArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<TransferCostDriverInfo[]>("rules/resourceTaskSplit/costDriver", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectFiltersSchema(args: SelectFiltersSchemaArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<SelectTransferFiltersSchema>("rules/resourceTaskSplit/filters/schema", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectFilterData(args: SelectFiltersDataArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<RuleFilterOption[]>("rules/resourceTaskSplit/filters/data", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectTargetFilterData(args: SelectFiltersDataArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<RuleFilterOption[]>("rules/resourceTaskSplit/filters/targetData", {
            subscriptionId: subscription,
            params: args
        });
    }


    saveGroup(args: SaveGroupArguments) {
        return this._post<SaveGroupResult>("rules/resourceTaskSplit/saveGroup",
            { body: args });
    }


    saveRule(args: SaveRuleArguments<TransferRuleEditableFields>) {
        return this._post<SaveRuleResult>("rules/resourceTaskSplit/save",
            { body: args });
    }


    deleteGroup(args: DeleteGroupArguments) {
        return this._post<void>("rules/resourceTaskSplit/deleteGroup",
            { body: args });
    }


    deleteRule(args: DeleteRuleArguments) {
        return this._post<void>("rules/resourceTaskSplit/delete",
            { body: args });
    }


    selectCost(args: SelectCostArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<SelectCostResult[]>("rules/resourceTaskSplit/cost", {
            subscriptionId: subscription,
            params: args
        });
    }
}
