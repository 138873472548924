import { DestroyRef, inject, QueryList } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { mixins } from "@logex/mixin-flavors";
import { HandleErrorsMixin } from "@logex/mixins";
import { LgEditableGridTextComponent, LgPromptDialog } from "@logex/framework/ui-core";
import { BehaviorSubject, firstValueFrom, Observable } from "rxjs";
import { LgPivotInstance, LgPivotInstanceFactory } from "@logex/framework/lg-pivot";
import { RulesPivot } from "../base/pivots/rules-pivot.service";
import { RulesPivotLevel1, RulesPivotLevel2, RulesPivotTotals } from "../base/pivots/rules-pivot.types";
import { RulesGateway } from "./gateway/rules-gateway";
import { AppDefinitions, AppSession, DefinitionKey, HelpTooltip, IAppDefinitions } from "@shared";
import { LG_APP_SESSION, LG_MESSAGE_BUS_SERVICE } from "@logex/framework/lg-application";
import { loadTargetFilters } from "@shared/services/rule-filter-selector/utils/loadTargetFilters";
import * as _ from "lodash";
import { CostDriverInfo, EditableGroupFields, Rule, RuleEditableFields, RuleGroup, SelectFiltersSchema } from "./gateway/rules-gateway.types";
import { RuleFilterInfo } from "@shared/services/rule-filter-selector/rule-filter-selector.types";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { LoadManager, STALE_DATA_SERVICE } from "@logex/load-manager";
import { DataSets, NEW_RULE_ID } from "./types/constants";
import { catchError, tap } from "rxjs/operators";
import { RuleEditorComponentBase } from "./rule-editor-component-base";
import { RuleSection } from "../../types/constants";


// Used in drag and drop as a type of the entry of flat ordered list of groups and rules - as they are seen.
type OrderedRulesNode<TRulesPivotLevel2 extends RulesPivotLevel2<Rule>> = [
    node: RulesPivotLevel1<TRulesPivotLevel2> | TRulesPivotLevel2,
    isGroup: boolean,
    position: number
];


export interface RuleSectionComponentBase extends HandleErrorsMixin {
}


@mixins(HandleErrorsMixin)
export abstract class RuleSectionComponentBase<TRulesPivotLevel2 extends RulesPivotLevel2<Rule> = RulesPivotLevel2<Rule>> {
    _promptDialog = inject(LgPromptDialog);
    _lgTranslate = inject(LgTranslateService);
    _destroyRef = inject(DestroyRef);

    private _loadManager = inject(LoadManager, { self: true });
    private _staleDataService = inject(STALE_DATA_SERVICE, { self: true });

    protected _session = inject<AppSession>(LG_APP_SESSION);
    protected _definitions = inject(AppDefinitions);

    protected _messageBus = inject(LG_MESSAGE_BUS_SERVICE);

    abstract _gateway: RulesGateway;

    protected _requiredDefinitions: Array<keyof IAppDefinitions>;

    constructor() {
        const pivotInstanceFactory = inject(LgPivotInstanceFactory);
        const rulesPivot = inject(RulesPivot);

        this._pivot = pivotInstanceFactory.create<RulesPivotLevel1<any>, RulesPivotTotals>(rulesPivot, this);
    }


    // ----------------------------------------------------------------------------------
    // Dialog configuration

    _dialogClass = "lg-dialog lg-dialog--8col";
    _title = this._lgTranslate.translate("APP._RulesDialog.DialogTitle");


    // ----------------------------------------------------------------------------------
    // Fields

    // @Input() must be in the derived class
    abstract isReadonly: boolean;

    abstract _currentSection: RuleSection;

    protected abstract _editor: RuleEditorComponentBase;
    abstract groupNames: QueryList<LgEditableGridTextComponent>;

    _currentRule: TRulesPivotLevel2;

    public isActivated = false;
    public _initialLoadingCompleted = false;
    protected _isModified = false;
    protected _isStaleSubj = new BehaviorSubject(false);
    isStale$: Observable<boolean> = this._isStaleSubj.asObservable();

    _rules: Rule[];
    _costs: Record<number, number>;
    _ruleGroups: RuleGroup[];
    protected _costDrivers: CostDriverInfo[];

    _pivot: LgPivotInstance<RulesPivotLevel1<any>, RulesPivotTotals>;

    public _sourceSelectorConfig: RuleFilterInfo[];

    _helpTooltip = HelpTooltip;

    protected _filterSchema: RuleFilterInfo[];
    _filterValue = "";

    protected _creatingGroup: RulesPivotLevel1<TRulesPivotLevel2>;
    private _isGroupRenaming = false;
    private _triggerReloadAfterGroupRenaming = false;
    protected _isRuleUpdatingInProgress = false;

    async activate(): Promise<void> {
        if (this.isActivated) return;

        if (this._requiredDefinitions != null) {
            await firstValueFrom(this._definitions.load(...this._requiredDefinitions));
        }

        this._addLoaders();
        this._loadManager.loadAll();
        
        this._staleDataService
            .isStale$(DataSets.GROUPS, DataSets.RULES, DataSets.COST_DRIVERS, DataSets.COSTS)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(isStale => {
                this._isStaleSubj.next(isStale);
            });

        // Subscribe rule change messages done by other users and reload when needed
        // this._messageBus.on("OnRuleChanged", (section: RuleSection) => {
        //     if (this._currentSection == null || section !== this._currentSection) return;
        //
        //     // If new group is being created, delay the pivot build until the new group is created
        //     if (this._isGroupRenaming === true) {
        //         this._triggerReloadAfterGroupRenaming = true;
        //         return;
        //     }
        //
        //     this._isModified = true;
        //     this._loadManager.reloadAll();
        // });

        this.isActivated = true;
    }

    private _addLoaders(): void {
        const args = {
            clientId: () => this._session.clientId,
            scenarioId: () => this._session.scenarioId,
        };

        this._loadManager.add({

            ...this._staleDataService.configureLoader(
                DataSets.RULES, {
                    args: [args],
                    require: [
                        DataSets.GROUPS,
                        DataSets.COST_DRIVERS,
                        DataSets.FILTER_SCHEMA,
                    ],
                    loader: (args, subscription) => this._gateway
                        .selectRules(args, subscription)
                        .pipe(
                            catchError(error => {
                                this._onException(error);
                                throw error;
                            })
                        )
                },
                async (data: Rule[], isStale) => {
                    this._rules = data;

                    this._processLoadedData(this._rules, this._ruleGroups);

                    this._initialLoadingCompleted = true;
                },
                (wasStale, isStale) => this._queryDatasetReload(wasStale, isStale)
            ),

            ...this._staleDataService.configureLoader(
                DataSets.COSTS, {
                    args: [args],
                    loader: (args, subscription) => this._gateway
                        .selectCost(args, subscription)
                        .pipe(
                            catchError(error => {
                                this._onException(error);
                                throw error;
                            })
                        )
                },
                async (data, isStale) => {
                    this._costs = data.reduce((acc, x) => {
                        acc[x.id] = x.cost;
                        return acc;
                    }, {});
                },
                (wasStale, isStale) => this._queryDatasetReload(wasStale, isStale)
            ),

            ...this._staleDataService.configureLoader(
                DataSets.GROUPS, {
                    args: [args],
                    loader: (args, subscription) => this._gateway
                        .selectRuleGroups(args, subscription)
                        .pipe(
                            catchError(error => {
                                this._onException(error);
                                throw error;
                            })
                        )
                },
                async (data, isStale) => {
                    this._ruleGroups = data;
                },
                (wasStale, isStale) => this._queryDatasetReload(wasStale, isStale)
            ),

            ...this._staleDataService.configureLoader(
                DataSets.COST_DRIVERS, {
                    args: [args],
                    loader: (args, subscription) => this._gateway
                        .selectCostDrivers(args, subscription)
                        .pipe(
                            catchError(error => {
                                this._onException(error);
                                throw error;
                            })
                        )
                },
                async (data, isStale) => {
                    this._costDrivers = data;
                },
                (wasStale, isStale) => this._queryDatasetReload(wasStale, isStale)
            ),

            ...this._staleDataService.configureLoader(
                DataSets.FILTER_SCHEMA, {
                    args: [args],
                    loader: (args, subscription) => this._gateway
                        .selectFiltersSchema(args, subscription)
                        .pipe(tap())
                },
                async (data, isStale) => {
                    this._filterSchema = data.filters;
                    await this._processFiltersSchema(data);
                },
                (wasStale, isStale) => this._queryDatasetReload(wasStale, isStale)
            ),
        })
    }
    

    private _queryDatasetReload(wasStale: boolean, isStale: boolean): boolean {
        // If new group is being created, delay the pivot build until the new group is created
        if (this._isGroupRenaming === true) {
            this._triggerReloadAfterGroupRenaming = true;
            return false;
        }
        return !isStale;
    }
    
    get isLoading(): boolean {
        return !this._initialLoadingCompleted;
    }


    protected async _processFiltersSchema(filtersSchema: SelectFiltersSchema): Promise<void> {
        this._sourceSelectorConfig = await loadTargetFilters(filtersSchema.filters, this._definitions, this._lgTranslate);
    }


    protected _processLoadedData(
        rules: Rule[],
        groups: RuleGroup[],
    ): void {

        // When reload's triggered by the message bus and the new rule is being created,
        // append "the fake rule" to pivot source data  
        if (this._currentRule?.id === NEW_RULE_ID) {
            rules.push(this._currentRule);
        }

        this._ruleGroups = this._ensureNonStandardGroupExists(groups);

        this._pivot.build(rules, null, true);
        this._pivot.refilter();

        // Check if there is any group without rules. We will need to add it manually to the pivot
        const groupsSet = new Set<number>();

        // Count the number of non-standard rules
        let nonStandardRulesCounter = 0;

        for (const group of groups) {
            groupsSet.add(group.id);

            if (!group.isStandard) {
                nonStandardRulesCounter++;
            }
        }

        _.each(this._pivot.all, row1 => {

            // If there is only one non-standard rule, show it expanded
            if (!row1.isStandard && nonStandardRulesCounter === 1) {
                row1.$expanded = true;
            }

            groupsSet.delete(row1.id);
        });

        // Add empty groups if there are any
        if (groupsSet.size > 0) {
            groupsSet.forEach(groupId => {
                const group = groups.find(x => x.id === groupId);
                this._pivot.all.push({
                    id: group.id,
                    name: group.name,
                    position: group.position,
                    isEnabled: group.isEnabled,
                    isStandard: group.isStandard,
                    $expanded: !group.isStandard,
                    children: [],
                    filteredChildren: []
                });
            });
            this._pivot.refilter();
            this._pivot.sort();
        }
    }


    private _ensureNonStandardGroupExists(groups: RuleGroup[]): RuleGroup[] {
        const nonStandardGroups = _.filter(groups, x => !x.isStandard);
        if (!_.isEmpty(nonStandardGroups)) return groups;

        return [
            ...groups,
            {
                id: this._getNextId(groups),
                position: this._getNextPosition(groups),
                name: this._lgTranslate.translate("APP._RulesDialog.DefaultRuleGroupName"),
                isEnabled: true,
                isStandard: false,
            }
        ];
    }


    private _getNextId(groups: Array<{ id: number; }>): number {
        const maxId = _.max(_.map(groups, x => x.id)) ?? 0;
        return (maxId < 0 ? 0 : maxId) + 1;
    }


    private _getNextPosition(groups: Array<{ position: number; }>): number {
        const maxPosition = _.max(_.map(groups, x => x.position)) ?? 0;
        return (maxPosition < 0 ? 0 : maxPosition) + 1;
    }


    async _deleteGroup($event: MouseEvent, row: RulesPivotLevel1<TRulesPivotLevel2>): Promise<void> {
        $event.preventDefault();
        $event.stopPropagation();

        let response = "yes";

        if (row.children.length > 0) {
            response = await this._promptDialog.confirm(
                this._lgTranslate.translate("APP._RulesDialog.ConfirmGroupRemoval.Title"),
                this._lgTranslate.translate("APP._RulesDialog.ConfirmGroupRemoval.Body"),
                {
                    buttons: [
                        {
                            id: "yes",
                            name: this._lgTranslate.translate("APP._RulesDialog.ConfirmGroupRemoval.Yes"),
                            isConfirmAction: true
                        },
                        {
                            id: "no",
                            name: this._lgTranslate.translate("APP._RulesDialog.ConfirmGroupRemoval.No"),
                            isCancelAction: true
                        }
                    ]
                }
            );
        }

        if (response === "yes") {
            _.remove(this._pivot.all, row);
            this._pivot.refilter();
            this._isModified = true;

            try {
                await firstValueFrom(this._gateway.deleteGroup({
                    clientId: this._session.clientId,
                    scenarioId: this._session.scenarioId,
                    id: row.id,
                }));
            } catch (e: any) {
                this._onException(e);
            }

            this._loadManager.reloadAll();
        }
    }


    async _deleteRule($event: MouseEvent, row2: TRulesPivotLevel2): Promise<void> {
        $event.preventDefault();
        $event.stopPropagation();
        if (this._isRuleUpdatingInProgress) return;
        this._isRuleUpdatingInProgress = true;

        const response = await this._promptDialog.confirm(
            this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleRemoval.Title"),
            this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleRemoval.Body"),
            {
                buttons: [
                    {
                        id: "yes",
                        name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleRemoval.Yes"),
                        isConfirmAction: true
                    },
                    {
                        id: "no",
                        name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleRemoval.No"),
                        isCancelAction: true
                    }
                ]
            }
        );

        if (response === "yes") {
            this._pivot.removeLeafNode(row2);
            this._pivot.refilter();
            this._isModified = true;

            this._normalizePositions(row2.groupId);

            try {
                await firstValueFrom(this._gateway.deleteRule({
                    clientId: this._session.clientId,
                    scenarioId: this._session.scenarioId,
                    id: row2.id,
                }));
            } catch (e: any) {
                this._onException(e);
            }
            this._isRuleUpdatingInProgress = false;
            // this._loadManager.reload(DataSets.RULES, DataSets.COSTS);
            this._loadManager.reload(DataSets.COSTS);
        }
    }


    // ----------------------------------------------------------------------------------
    // Drag and drop

    async _dropGroup(event: CdkDragDrop<Rule>): Promise<void> {
        const newPosition = this._dropNode(event, (event, node, nodes) => {
            const targetPositionInNodes = event.currentIndex - event.previousIndex + node[2];
            const targetNode = nodes[targetPositionInNodes];

            // You can drop only on another group or on the last rule in a group
            if (targetNode[1] // Target is a group
                || targetPositionInNodes === nodes.length - 1) { // Target is the very last node
                return targetPositionInNodes;
            }

            // Otherwise you cannot drop here
            return undefined;
        });

        await this._updateGroup(event.item.data.id, {
            position: newPosition,
        });

        this._loadManager.reloadAll();
    }


    async _dropRule(event: CdkDragDrop<Rule>): Promise<void> {
        const newPosition = this._dropNode(event, (event, node) => {
            const targetPositionInNodes = event.currentIndex - event.previousIndex + node[2];
            if (targetPositionInNodes === 0) {
                // You cannot put a rule before the first group
                return 1;
            }
            return targetPositionInNodes;
        });

        this._loadManager.cancel(DataSets.RULES);

        await this._updateRule(event.item.data.id, {
            position: newPosition,
            groupId: event.item.data.groupId,
        });

        this._loadManager.reload(DataSets.COSTS);
    }


    private _dropNode(
        event: CdkDragDrop<Rule>,
        getTargetPosition: (
            event: CdkDragDrop<Rule>,
            node: OrderedRulesNode<TRulesPivotLevel2>,
            nodes: Array<OrderedRulesNode<TRulesPivotLevel2>>
        ) => number
    ): number {

        // Do not perform drag-n-drop if user visibly drag the element outside of the container
        if (!event.isPointerOverContainer) return;

        // Make a list of all nodes in the correct order
        const nodes = this._makeOrderedRulesArray(this._pivot.filtered);

        // Find node corresponding to the dropped row
        const node = _.find(nodes, x => x[0] === event.item.data);

        if (node == null) {
            throw new Error(`Didn't find dropped element - SHOULDN'T happen`);
        }

        const targetPositionInNodes = getTargetPosition(event, node, nodes);

        // Drop could be cancelled
        if (targetPositionInNodes === undefined) return;

        // Do not drop before any standard rule
        if (nodes[targetPositionInNodes][0].isStandard) return;
        if (nodes[targetPositionInNodes][1]
            && targetPositionInNodes > 0
            && nodes[targetPositionInNodes - 1][0].isStandard
        ) return;

        // Move dragged node in nodes array
        let nodesToMove = 1;

        // If this is a group - move all the content
        if (node[1]) {
            for (let i = node[2] + 1; i < nodes.length && !nodes[i][1]; i++) {
                nodesToMove++;
            }
        }

        nodes.splice(
            targetPositionInNodes > node[2] ? targetPositionInNodes - nodesToMove + 1 : targetPositionInNodes,
            0,
            ...nodes.splice(node[2], nodesToMove)
        );

        // Renumber the nodes from the beginning
        this._repositionOrderedRules(nodes);

        this._pivot.refilter();

        return node[0].position;
    }


    private _makeOrderedRulesArray(level1: Array<RulesPivotLevel1<TRulesPivotLevel2>>): Array<OrderedRulesNode<TRulesPivotLevel2>> {
        const nodes: Array<OrderedRulesNode<TRulesPivotLevel2>> = [];
        let position = 0;
        _.each(level1, row1 => {
            nodes.push([row1, true, position++]);

            // Add child nodes only if parent node is expanded
            if (row1.$expanded) {
                _.each(row1.filteredChildren, row2 => {
                    nodes.push([row2, false, position++]);
                });
            }
        });
        return nodes;
    }


    private _repositionOrderedRules(nodes: Array<OrderedRulesNode<TRulesPivotLevel2>>): void {
        let i = 0;
        let currentGroup: RulesPivotLevel1<TRulesPivotLevel2>;
        let groupPosition = 0;
        let rulePosition;

        _.each(nodes, x => {
            // If group
            if (x[1]) {
                currentGroup = x[0] as RulesPivotLevel1<TRulesPivotLevel2>;

                // Skip standard groups from repositioning
                if (!currentGroup.isStandard) {
                    groupPosition++;

                    if (currentGroup.position !== groupPosition) {
                        currentGroup.position = groupPosition;
                        this._isModified = true;
                    }

                    // Reset rules counter
                    rulePosition = 0;
                }
            } else {
                const currentRule = x[0] as TRulesPivotLevel2;

                // Skip standard rules from repositioning
                if (!currentRule.isStandard) {
                    rulePosition++;

                    if (currentRule.groupId !== currentGroup.id) {
                        // Move to another group
                        this._pivot.removeLeafNode(currentRule);
                        currentRule.groupId = currentGroup.id;
                        currentRule.position = rulePosition;
                        this._pivot.reattachLeafNode(currentRule);
                        this._isModified = true;

                    } else if (currentRule.position !== rulePosition) {
                        currentRule.position = rulePosition;
                        this._isModified = true;
                    }
                }
            }

            // Update the node index
            x[2] = i++;
        });
    }


    // ----------------------------------------------------------------------------------
    // Editing

    protected async _toggleRuleGroup(row1: RulesPivotLevel1<TRulesPivotLevel2>): Promise<void> {
        await this._updateGroup(row1.id, { isEnabled: row1.isEnabled });
        // this._loadManager.reload(DataSets.GROUPS, DataSets.COSTS);
        this._loadManager.reload(DataSets.COSTS);
    }

    protected async _toggleRule(row2: TRulesPivotLevel2): Promise<void> {
        await this._updateRule(row2.id, { isEnabled: row2.isEnabled });
        // this._loadManager.reload(DataSets.RULES, DataSets.COSTS);
        this._loadManager.reload(DataSets.COSTS);
    }

    protected async _selectRule(row2: TRulesPivotLevel2): Promise<void> {

        if (this._editor?.isChanged()) {
            const response = await this._promptDialog.confirm(
                this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleSave.Title"),
                this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleSave.Body"),
                {
                    buttons: [
                        {
                            id: "save",
                            name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleSave.Save"),
                            isConfirmAction: true
                        },
                        {
                            id: "cancel",
                            name: this._lgTranslate.translate("APP._RulesDialog.ConfirmRuleSave.Cancel"),
                            isCancelAction: true
                        }
                    ]
                }
            );

            if (response === "save") {
                this._editor.save();
            }
        }

        if (this._currentRule?.id === NEW_RULE_ID) {
            const ruleNode = this._getNode(this._currentRule.groupId, this._currentRule.id);
            this._pivot.removeLeafNode(ruleNode);
            this._pivot.refilter();
        }

        // In order to re-render editor fully we do it like this 

        const newValue = this._currentRule?.id === row2.id
            ? null
            : row2;

        this._currentRule = null;

        if (newValue == null) return;

        requestAnimationFrame(() => {
            this._currentRule = newValue;
        });
    }

    _closeRule(): void {

        // If the rule is new and not saved yet - remove it from the pivot
        if (this._currentRule?.id === NEW_RULE_ID) {
            const ruleNode = this._getNode(this._currentRule.groupId, this._currentRule.id);
            this._pivot.removeLeafNode(ruleNode);
            this._pivot.refilter();
        }

        this._currentRule = null;
    }

    _isEditing(): boolean {
        return this._currentRule != null;
    }

    async _addRuleGroup(): Promise<void> {

        // Unset the filter
        this._filterValue = "";
        this._pivot.refilter();

        const newGroup = this._newGroup(
            null,
            this._pivot.all.length + 1
        );
        this._ruleGroups.push(newGroup);
        this._pivot.all.push(newGroup);
        this._pivot.refilter();
        this._isModified = true;
        this._pivot.ensureVisible(newGroup.id);

        setTimeout(() => {
            this.groupNames?.last.startEditing();
        }, 10);

        this._creatingGroup = newGroup;
    }


    async _groupRenameStart(): Promise<void> {
        this._isGroupRenaming = true;
    }


    async _groupRename(newName: string, groupId: number): Promise<void> {
        if (this._creatingGroup != null) {
            if (newName.length === 0) {
                const groupNode = this._pivot.all.find(x => x.id === this._creatingGroup.id);
                _.remove(this._pivot.all, groupNode);
                this._pivot.refilter();
            } else {
                this._creatingGroup.id = await this._updateGroup(this._creatingGroup.id, this._getGroupPropsForSaving(this._creatingGroup));
            }
        } else {
            await this._updateGroup(groupId, { name: newName });
        }

        this._creatingGroup = null;
        this._isGroupRenaming = false;

        if (this._triggerReloadAfterGroupRenaming) {
            this._triggerReloadAfterGroupRenaming = false;
            this._loadManager.reloadAll();
        }
    }


    async _addRule(event: MouseEvent | null, groupId: number): Promise<void> {
        event?.stopPropagation();
        const groupNode = this._pivot.all.find(x => x.id === groupId);
        const position = Math.max(0, ...groupNode.children.map(x => x.position)) + 1;

        const row = this._newRule(groupId, position);
        groupNode.$expanded = true;
        this._isModified = true;

        this._pivot.reattachNodeAndEnsureVisible(row, [groupId, position]);

        row.id = NEW_RULE_ID;
        this._rules.push(row);
        await this._selectRule(row);
    }


    async _cloneRule(event: MouseEvent, row2: TRulesPivotLevel2): Promise<void> {
        event.stopPropagation();
        if (this._isRuleUpdatingInProgress) return;
        this._isRuleUpdatingInProgress = true;

        const groupNode = this._pivot.all.find(x => x.id === row2.groupId);

        // Adjust the positions of the subsequent rules
        for (let x of groupNode.children) {
            if (x.position <= row2.position) continue;
            x.position++;
        }

        const newPosition = row2.position + 1;

        // Clone the row
        const clonedRow = {
            ...row2,
            id: undefined,
            position: newPosition,
        };

        this._isModified = true;

        // Add new element to the pivot
        groupNode.children.splice(newPosition, 0, clonedRow);
        this._pivot.refilter();

        // Open details
        await this._selectRule(clonedRow);

        // Save the rule
        try {
            const newRuleId = await firstValueFrom(this._gateway.saveRule({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                rule: this._getRulePropsForSaving(clonedRow),
            }));

            clonedRow.id = newRuleId.id;
        } catch (e: any) {
            this._onException(e);
        }
        this._isRuleUpdatingInProgress = false;
        this._loadManager.reloadAll();
    }


    protected abstract _newRule(groupId: number, position: number): TRulesPivotLevel2;


    async _renameRule(id: number, newName: string): Promise<void> {
        await this._updateRule(id, { name: newName });
    }


    private _normalizePositions(groupId: number): void {
        this._pivot.all.find(x => x.id === groupId)?.children
            .sort(((a, b) => a.position - b.position))
            .forEach(((x, i) => {
                x.position = i + 1;
            }));
    }


    // ----------------------------------------------------------------------------------
    // Save

    async _save(id: number | null, update: Partial<RuleEditableFields>): Promise<void> {

        const isNewRule = this._currentRule.id === NEW_RULE_ID;

        // Patch pivot row
        const ruleNode = this._getNode(this._currentRule.groupId, this._currentRule.id);
        Object.assign(ruleNode, update);
        this._currentRule = null;

        // For new rules we have to pass by all the fields
        const saveData = isNewRule
            ? this._getRulePropsForSaving(ruleNode)
            : update;

        // Update rule id in pivot for new rules
        const ruleId = await this._updateRule(id, saveData);
        if (isNewRule) {
            Object.assign(ruleNode, {
                id: ruleId
            });
        }

        // this._loadManager.reload(DataSets.RULES, DataSets.COSTS);
        this._loadManager.reload(DataSets.COSTS);
    }

    async _saveAndNew(id: number | null, update: Partial<RuleEditableFields>): Promise<void> {
        const currentGroupId = this._currentRule.groupId;

        await this._save(id, update);
        await this._addRule(null, currentGroupId);
    }

    async _deleteCurrentRule(): Promise<void> {
        if (this._isRuleUpdatingInProgress) return;
        this._isRuleUpdatingInProgress = true;
        // Patch pivot row
        const ruleNode = this._getNode(this._currentRule.groupId, this._currentRule.id);
        this._currentRule = null;

        this._pivot.removeLeafNode(ruleNode);
        this._pivot.refilter();
        this._isModified = true;

        try {
            await firstValueFrom(this._gateway.deleteRule({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                id: ruleNode.id,
            }));
        } catch (e: any) {
            this._onException(e);
        }
        this._isRuleUpdatingInProgress = false;
        // this._loadManager.reload(DataSets.RULES, DataSets.COSTS);
        this._loadManager.reload(DataSets.COSTS);
    }

    protected async _updateGroup(id: number | null, update: Partial<EditableGroupFields>): Promise<number> {
        const group = this._ruleGroups.find(x => x.id === id);

        try {
            this._isModified = true;

            const saveResult = await firstValueFrom(this._gateway.saveGroup({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                id,
                ...(group != null ? this._getGroupPropsForSaving(group) : {} as RuleGroup),
                ...update,
            }));

            return saveResult.id;
        } catch (e: any) {
            this._onException(e);
        }
    }

    protected async _updateRule(id: number | null, update: Partial<RuleEditableFields>): Promise<number> {
        const rule = this._rules.find(x => x.id === id);

        try {
            this._isModified = true;
            this._isRuleUpdatingInProgress = true;
            
            const saveResult = await firstValueFrom(this._gateway.saveRule({
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                rule: {
                    id,
                    ...(rule != null ? this._getRulePropsForSaving(rule) : {} as Rule),
                    ...update
                },
            }));
            this._isRuleUpdatingInProgress = false;
            return saveResult.id;
        } catch (e: any) {
            this._onException(e);
        }
    }

    protected _getGroupPropsForSaving(row: RuleGroup): EditableGroupFields {
        return {
            id: row.id,
            position: row.position,
            name: row.name,
            isEnabled: row.isEnabled,
        }
    }

    protected _getRulePropsForSaving(rule: RuleEditableFields): RuleEditableFields {
        return {
            groupId: rule.groupId,
            position: rule.position,
            name: rule.name,
            costDriverId: rule.costDriverId,
            isEnabled: rule.isEnabled,
            filters: rule.filters
        }
    }

    _refilter(): void {
        this._pivot.refilter();
    }


    // ----------------------------------------------------------------------------------
    // Renderers

    protected _renderSources(filters: Record<string, unknown[]>): string {
        return this._renderSelection(this._sourceSelectorConfig, filters, "APP._RulesDialog.Sources");
    }

    protected _renderSelectionTooltip(filters: Record<string, unknown[]>): string {
        if (filters == null) return null;
        const keys = Object.keys(filters);
        if (keys.length === 0 || keys.length > 1) return "";
        const firstKey = keys[0];
        const schemaItem = this._filterSchema.find(x => x.uid === firstKey);
        const firstSelection = filters[firstKey];

        if (schemaItem?.type == null) return null;

        return firstSelection.slice(0, 5).map(x => this._definitions.getDisplayName(schemaItem.type as DefinitionKey, x)).join("<br />")
            + (firstSelection.length > 5 ? "<br />..." : "");
    }

    protected _renderSelection(config: RuleFilterInfo[], filters: Record<string, unknown[]>, badgeLc: string): string {
        if (config == null) return "";

        const values = (filters ? Object.values(filters) : []).flat();

        if (values.length === 0) return "";

        if (values.length === 1) {
            const key = Object.keys(filters)[0];
            const fieldInfo = config.find(x => x.uid === key);
            if (fieldInfo.type == null) {
                return fieldInfo.name;
            }
            return this._definitions.getDisplayName(fieldInfo.type as DefinitionKey, values[0]);
        }

        return `${values.length} ${this._lgTranslate.translate(badgeLc)}`;
    }

    protected _renderRuleImpact(ruleId: number): number | undefined {
        if (this._costs && this._costs[ruleId] != null) return this._costs[ruleId];
        return undefined;
    }


    // ----------------------------------------------------------------------------------
    // Helpers

    public isModified(): boolean {
        return this._isModified;
    }

    private _newGroup(groupId: number, position: number): RulesPivotLevel1<TRulesPivotLevel2> {
        return {
            id: groupId,
            position,
            name: "",
            isEnabled: true,
            isStandard: false,
            children: [],
            filteredChildren: [],
            $expanded: true,
        };
    }

    private _getNode(groupId: number, ruleId: number): TRulesPivotLevel2 {
        const groupNode = this._pivot.all.find(x => x.id === groupId);
        return groupNode.children.find(x => x.id === ruleId);
    }

    protected _isRuleSelected(ruleId: number): boolean {
        return this._currentRule?.id === ruleId;
    }

}
