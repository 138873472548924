import { inject, Injectable } from "@angular/core";
import { MaybeStaleServerGatewayBase } from "@logex/load-manager";
import { SelectDefinitionArgument } from "@shared/app-definitions.types";
import { IAppConfiguration, IDefinitionSectionInfo, LG_APP_CONFIGURATION } from "@logex/framework/lg-application";

@Injectable()
export class AppDefinitionGateway extends MaybeStaleServerGatewayBase {
    constructor() {
        super();
        const appConfig = inject<IAppConfiguration>(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfig.applicationRoot);
    }
    
    selectDefinition(args: SelectDefinitionArgument, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<IDefinitionSectionInfo>("api/definitions", {
            subscriptionId: subscription,
            params: args
        });
    }
}