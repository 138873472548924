import { Injectable } from "@angular/core";

import { AppServerGatewayBase } from "@shared/bases/app-server-gateway-base";
import { RuleFilterOption } from "@shared/services/rule-filter-selector/rule-filter-selector.types";

import {
    DeleteGroupArguments,
    DeleteRuleArguments,
    RuleGroup,
    SaveGroupArguments,
    SaveGroupResult,
    SaveRuleArguments,
    SaveRuleResult,
    SelectCostArguments,
    SelectCostDriverArguments,
    SelectCostResult,
    SelectFiltersDataArguments,
    SelectFiltersSchemaArguments,
    SelectRuleGroupArguments,
    SelectRulesArguments
} from "../../base/gateway/rules-gateway.types";
import { SelectTransferFiltersSchema, TransferRuleEditableFields, } from "../../base/gateway/transfer-rule-gateway.type";
import { RulesGateway } from "../../base/gateway/rules-gateway";
import { TransferCostDriverInfoExt, TransferRuleExt } from "../../base/gateway/transfer-rule-with-method-gateway.type";


@Injectable()
export class SupportCostTransferRulesGateway extends AppServerGatewayBase implements RulesGateway<TransferRuleExt, TransferCostDriverInfoExt> {

    selectRules(args: SelectRulesArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<TransferRuleExt[]>("rules/resourceTaskTransfer", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectRuleGroups(args: SelectRuleGroupArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<RuleGroup[]>("rules/resourceTaskTransfer/groups", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectCostDrivers(args: SelectCostDriverArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2 <TransferCostDriverInfoExt[]>("rules/resourceTaskTransfer/costDriver", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectFiltersSchema(args: SelectFiltersSchemaArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<SelectTransferFiltersSchema>("rules/resourceTaskTransfer/filters/schema", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectFilterData(args: SelectFiltersDataArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<RuleFilterOption[]>("rules/resourceTaskTransfer/filters/data", {
            subscriptionId: subscription,
            params: args
        });
    }


    selectTargetFilterData(args: SelectFiltersDataArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<RuleFilterOption[]>("rules/resourceTaskTransfer/filters/targetData", {
            subscriptionId: subscription,
            params: args
        });
    }


    saveGroup(args: SaveGroupArguments) {
        return this._post<SaveGroupResult>("rules/resourceTaskTransfer/saveGroup",
            { body: args });
    }


    saveRule(args: SaveRuleArguments<TransferRuleEditableFields>) {
        return this._post<SaveRuleResult>("rules/resourceTaskTransfer/save",
            { body: args });
    }


    deleteGroup(args: DeleteGroupArguments) {
        return this._post<void>("rules/resourceTaskTransfer/deleteGroup",
            { body: args });
    }


    deleteRule(args: DeleteRuleArguments) {
        return this._post<void>("rules/resourceTaskTransfer/delete",
            { body: args });
    }


    selectCost(args: SelectCostArguments, subscription: string | undefined | null) {
        return this._getMaybeStaleV2<SelectCostResult[]>("rules/resourceTaskTransfer/cost", {
            subscriptionId: subscription,
            params: args
        });
    }
}
