import { Component, inject, isDevMode, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

import {
    LG_APP_INFO,
    LG_APP_SESSION,
    LG_FEATURES,
    LG_USER_INFO,
    LgNavigationService,
    VersionService
} from "@logex/framework/lg-application";
import { SidebarHelpLinkItem } from "@logex/framework/lg-layout";
import { LG_LOCALIZATION_SETTINGS, LgTranslateService } from "@logex/framework/lg-localization";
import { NgOnDestroyMixin } from "@logex/mixins";
import { mixins } from "@logex/mixin-flavors";

import { ApplicationPermissions } from "@shared/app-permissions";
import { APP_HELP_LINKS, ExtendedSidebarHelpLinks } from "@shared/app-help";
import { AppLgLocalizationSettings } from "@shared/services/app-lg-localization-settings";
import { APP_BANNER_CONFIG, IAppBannerConfig } from "@shared/types/app-banner";
import { AppFeatures, HidePagesFeatureConfiguration } from "@shared/app-features";
import { AppIcons } from "@shared/app-icons";
import { APP_VERSION_TITLE, AppSession } from "@shared";


export interface AuthorizedComponent extends NgOnDestroyMixin {
}

interface SideBarMenuItem {
    id: string;
    nameLc: string;
    icon: AppIcons;
    isVisible: boolean;
}

@Component({
    selector: "app-authorized-component",
    templateUrl: "./authorized.component.html",
    standalone: false
})
@mixins(NgOnDestroyMixin)
export class AuthorizedComponent implements OnDestroy {

    _appHelpLinks = inject<ExtendedSidebarHelpLinks>(APP_HELP_LINKS);
    _additionalLinks: SidebarHelpLinkItem[] = [];
    _localizationSettings = inject<AppLgLocalizationSettings>(LG_LOCALIZATION_SETTINGS);
    appBannerConfig = inject<IAppBannerConfig>(APP_BANNER_CONFIG);
    appInfo = inject(LG_APP_INFO);
    userInfo = inject(LG_USER_INFO);

    protected _appVersionTitle = inject(APP_VERSION_TITLE);
    protected _session = inject<AppSession>(LG_APP_SESSION);
    private _navigationService = inject(LgNavigationService);
    private _router = inject(Router);
    private _versionService = inject(VersionService<any>);
    private _features = inject(LG_FEATURES);
    private _lgTranslate = inject(LgTranslateService);

    constructor() {
        this._initMixins();

        this._internal = this.userInfo.hasPermission(ApplicationPermissions.INTERNAL);

        /* this._viewSettings = userInfo.hasPermission( _permissions.ViewSettings );
        this._refresh = userInfo.hasPermission( _permissions.EditSettings );*/

        this._viewExports = this.userInfo.hasPermission(ApplicationPermissions.EXPORTS);
        this._viewSettings = true;

        this._scenarioDialog = this.userInfo.hasPermission(ApplicationPermissions.SWITCH_SCENARIO)
            || this.userInfo.hasPermission(ApplicationPermissions.MANAGE_SCENARIOS);

        this._devMode = isDevMode() || this._internal;
        this._versionService.initialize();
        if (this.appInfo.productId != null) {
            this._currentProductIcon = "icon-product-logo-36x36_" + this.appInfo.productId.toLowerCase();
        }

        this._bannerHeader = this.appBannerConfig.heading;
        this._bannerText = this.appBannerConfig.body;
        this._bannerButtonText = this.appBannerConfig.buttonText;
        this._bannerButtonLink = this.appBannerConfig.buttonLink;
        this._bannerVisible = this._bannerHeader != null && this._bannerText != null
            && this._bannerButtonText != null && this._bannerButtonLink != null;

        const feature = this._features.getFeature<HidePagesFeatureConfiguration>(AppFeatures.HIDE_PAGES);
        const configuration = feature.configuration;
        this._hiddenPages = new Set((configuration && configuration.pages) ?? []);

        this._sideBarMenuItems = [
            {
                id: "data",
                nameLc: "APP._Sidebar.Data",
                icon: AppIcons.SidebarData,
                isVisible: !this._hiddenPages.has("data")
                    && this._navigationService.getNavigationSync("data").length > 0,
            },
            {
                id: "rules",
                nameLc: "APP._Sidebar.Rules",
                icon: AppIcons.SidebarRules,
                isVisible: !this._hiddenPages.has("rules")
                    && this._navigationService.getNavigationSync("rules").length > 0,
            },
            {
                id: "modeling",
                nameLc: "APP._Sidebar.Modeling",
                icon: AppIcons.SidebarModel,
                isVisible: !this._hiddenPages.has("modeling")
                    && this._navigationService.getNavigationSync("modeling").length > 0,
            },
            {
                id: "analytics",
                nameLc: "APP._Sidebar.Analytics",
                icon: AppIcons.SidebarAnalyse,
                isVisible: !this._hiddenPages.has("analytics")
                    && this._navigationService.getNavigationSync("analytics").length > 0,
            },
        ];
        
        if (this._appHelpLinks.checklist) {
            this._additionalLinks = [{
                name: this._lgTranslate.translate("APP._HelpLinkInfo.Checklist"),
                url: this._appHelpLinks.checklist,
                icon: "icon-numbered-list"
            }];
        }
        delete this._appHelpLinks["checklist"];

    }

    // ----------------------------------------------------------------------------------
    //
    _devMode: boolean;
    _currentProductIcon: string;
    _internal: boolean;
    _viewSettings: boolean;
    _viewExports: boolean;
    _scenarioDialog: boolean;
    _userMessages: number;
    _refresh: boolean;

    _supportLink: string;

    _notesCountTM: number;

    _bannerVisible = false;
    _bannerHeader: string;
    _bannerText: string;
    _bannerButtonText: string;
    _bannerButtonLink: string;

    _hiddenPages: Set<string>;
    _sideBarMenuItems: SideBarMenuItem[];


    // ----------------------------------------------------------------------------------
    //

    _closeBanner(): void {
        this._bannerVisible = false;
    }


    _navigateToExports($event: Event): void {
        $event.preventDefault();
        this._navigationService.navigateTo(this._navigationService.getNodeByIdSync("exports"));
    }


    _openDashboard(): void {
        this._router.navigate(["dashboard"]);
    }
}
