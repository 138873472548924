import { Component, inject, Input, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { LoadManager, useStaleData } from "@logex/load-manager";
import { RulesPivot } from "../base/pivots/rules-pivot.service";
import { SupportCostTransferRulesGateway } from "./gateways/support-cost-transfer-rules-gateway";
import { IAppDefinitions } from "@shared";
import { RuleSection } from "../../types/constants";
import { RuleEditorComponentBase } from "@shared/dialogs/rules-dialog/components/base/rule-editor-component-base";
import { LgEditableGridTextComponent } from "@logex/framework/ui-core";
import { TransferRuleWithMethodSectionComponentBase } from "../base/transfer-rule-with-method-section-component-base";

@Component({
    selector: "mod-support-cost-transfer-rules",
    templateUrl: "./support-cost-transfer.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.SupportCostTransfer"),
        LoadManager,
        useStaleData(),
        RulesPivot,
        SupportCostTransferRulesGateway,
    ],
    standalone: false
})
export class SupportCostTransferRulesSectionComponent extends TransferRuleWithMethodSectionComponentBase {

    _gateway = inject(SupportCostTransferRulesGateway);

    @Input() isReadonly = false;

    @ViewChild("editor") protected _editor: RuleEditorComponentBase;
    @ViewChildren("groupName") groupNames: QueryList<LgEditableGridTextComponent>;

    _currentSection = RuleSection.SupportCostTransfer;


    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["resourceTaskTransferMethod"];

}
