import { inject, Pipe, PipeTransform } from "@angular/core";
import { AppDefinitions, DefinitionDisplayMode } from "@shared/app-definitions.service";
import { StringKeyOf } from "@logex/framework/types";
import { IAppDefinitions } from "@shared";


@Pipe({
    name: "asDefinition",
    standalone: false
})
export class AsDefinitionPipe implements PipeTransform {
    protected _appDefinitions = inject(AppDefinitions);

    // Note: we could add caching here, but normally we expect the pipe is pure; and if not it probably has a reason and doesn't want to be cached

    transform(value: any, definition: StringKeyOf<IAppDefinitions>, format?: DefinitionDisplayMode, empty?: string): any {
        return this._appDefinitions.getDisplayName(definition, value, format as DefinitionDisplayMode);
    }

}
