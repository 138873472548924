import { Component, inject, Input, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { RuleSectionComponentBase } from "../base/rule-section-component-base";
import { RulesPivot } from "../base/pivots/rules-pivot.service";
import { SubaccountSpecificationRulesGateway } from "./gateway/subaccount-specification-rules-gateway.service";
import { RuleEditableFieldsExt } from "./gateway/subaccount-specification-rules-gateway.types";
import { RulesPivotLevel2Ext } from "./pivots/transfer-rules-pivot.types";
import { SubAccountManagementDialog } from "@shared/dialogs/sub-account-management-dialog/sub-account-management-dialog.component";
import { LoadManager, useStaleData } from "@logex/load-manager";
import { RuleSection } from "../../types/constants";
import { IAppDefinitions } from "@shared";
import { RuleEditorComponentBase } from "@shared/dialogs/rules-dialog/components/base/rule-editor-component-base";
import { LgEditableGridTextComponent } from "@logex/framework/ui-core";


@Component({
    selector: "mod-subaccount-specification-rules",
    templateUrl: "./subaccount-specification.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.SubaccountSpecification"),
        LoadManager,
        useStaleData(),
        RulesPivot,
        SubaccountSpecificationRulesGateway,
    ],
    standalone: false
})
export class SubaccountSpecificationRulesSectionComponent extends RuleSectionComponentBase<RulesPivotLevel2Ext> {

    private _subAccountManagementDialog = inject(SubAccountManagementDialog);
    _gateway = inject(SubaccountSpecificationRulesGateway);

    @Input() isReadonly = false;

    @ViewChild("editor") protected _editor: RuleEditorComponentBase;
    @ViewChildren("groupName") groupNames: QueryList<LgEditableGridTextComponent>;

    _currentSection = RuleSection.SubaccountSpecification;

    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["account", "subAccount"];


    protected _getRulePropsForSaving(rule: RuleEditableFieldsExt): RuleEditableFieldsExt {
        return {
            ...super._getRulePropsForSaving(rule),
            filters: rule.filters,
            subAccounts: rule.subAccounts,
        }
    }


    protected _newRule(groupId: number, position: number) {
        return {
            groupId,
            position,
            isEnabled: true,
            name: "",
            subAccounts: [],
        } as RulesPivotLevel2Ext;
    }


    _renderSubaccountsTooltip(subAccounts: string[]): string {
        if (subAccounts == null || subAccounts.length === 0) {
            return "";
        }

        if (subAccounts.length === 1) {
            return this._definitions.getDisplayName("subAccount", subAccounts[0]);
        }

        return subAccounts.slice(0, 5).map(x => this._definitions.getDisplayName("subAccount", x)).join("<br />")
            + (subAccounts.length > 5 ? "<br />..." : "");
    }


    _renderSubaccounts(subAccounts: string[]): string {
        if (subAccounts == null || subAccounts.length === 0) {
            return "";
        }

        if (subAccounts.length === 1) {
            return "1 " + this._lgTranslate.translate(".SubAccount");
        }

        return subAccounts.length + " " + this._lgTranslate.translate(".SubAccounts");
    }


    _openSubAccountManager(): void {
        this._subAccountManagementDialog
            .show({
                isReadonly: this.isReadonly,
            });
    }
}
