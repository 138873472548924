import { Component, inject, Input, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { LoadManager, useStaleData } from "@logex/load-manager";
import { RulesPivot } from "../base/pivots/rules-pivot.service";
import { ResourceTransferRulesGateway } from "./gateways/resource-transfer-rules-gateway";
import { IAppDefinitions } from "@shared";
import { RuleSection } from "../../types/constants";
import { RuleEditorComponentBase } from "../base/rule-editor-component-base";
import { LgEditableGridTextComponent } from "@logex/framework/ui-core";
import { TransferRuleWithMethodSectionComponentBase } from "../base/transfer-rule-with-method-section-component-base";

@Component({
    selector: "mod-resource-transfer-rules",
    templateUrl: "./resource-transfer.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.ResourceTransfer"),
        LoadManager,
        useStaleData(),
        RulesPivot,
        ResourceTransferRulesGateway,
    ],
    standalone: false
})
export class ResourceTransferRulesSectionComponent extends TransferRuleWithMethodSectionComponentBase {

    _gateway = inject(ResourceTransferRulesGateway);

    @Input() isReadonly = false;

    @ViewChild("editor") protected _editor: RuleEditorComponentBase;
    @ViewChildren("groupName") groupNames: QueryList<LgEditableGridTextComponent>;

    _currentSection = RuleSection.ResourceTransfer;


    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["resourceTransferMethod"];

}
