import { IAppConfiguration, LG_APP_CONFIGURATION, ServerGatewayBase } from "@logex/framework/lg-application";
import { inject } from "@angular/core";
import { MaybeStaleServerGatewayBase } from "@logex/load-manager";


export class AppServerGatewayBase extends MaybeStaleServerGatewayBase {

    constructor() {
        super();
        const appConfig = inject<IAppConfiguration>(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfig.applicationRoot);
    }
}
