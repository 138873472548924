import { Component, inject, Input, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { LoadManager, useStaleData } from "@logex/load-manager";
import { RulesPivot } from "../base/pivots/rules-pivot.service";
import { MapToProductionRulesGateway } from "./gateways/map-to-production-rules-gateway";
import { TransferRuleSectionComponentBase } from "../base/transfer-rule-section-component-base";
import { IAppDefinitions } from "@shared";
import { RuleSection } from "../../types/constants";
import { RuleEditorComponentBase } from "@shared/dialogs/rules-dialog/components/base/rule-editor-component-base";
import { LgEditableGridTextComponent } from "@logex/framework/ui-core";

@Component({
    selector: "mod-map-to-production-rules",
    templateUrl: "./map-to-production.component.tshtml",
    encapsulation: ViewEncapsulation.None,
    providers: [
        ...useTranslationNamespace("APP._RulesDialog.MapToProduction"),
        LoadManager,
        useStaleData(),
        RulesPivot,
        MapToProductionRulesGateway,
    ],
    standalone: false
})
export class MapToProductionRulesSectionComponent extends TransferRuleSectionComponentBase {

    _gateway = inject(MapToProductionRulesGateway);

    @Input() isReadonly = false;

    @ViewChild("editor") protected _editor: RuleEditorComponentBase;
    @ViewChildren("groupName") groupNames: QueryList<LgEditableGridTextComponent>;

    _currentSection = RuleSection.MapToProduction;


    protected _requiredDefinitions: Array<keyof IAppDefinitions> = ["subtask"];
    
}
