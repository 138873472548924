import { Injectable, inject } from "@angular/core";
import * as _ from "lodash";

import {
    DefinitionDisplayMode,
    LG_APP_CONFIGURATION,
} from "@logex/framework/lg-application";
import { StringKeyOf } from "@logex/framework/types";

import { AppServerDefinitionsBase } from "@shared/AppServerDefinitionsBase";
import {
    DefinitionCodeName,
    DefinitionDisplayCodeName,
    DefinitionsAccount,
    DefinitionsAccountAllocationRule,
    DefinitionsAccountAllocationRuleGroup,
    DefinitionsAccountBenchmarkType,
    DefinitionsAccountBenchmarkTypeGroup,
    DefinitionsAccountBenchmarkTypeSubgroup,
    DefinitionsAccountBenchmarkTypeTopgroup,
    DefinitionsAccountDimension1,
    DefinitionsAccountDimension1RegulationType,
    DefinitionsAccountDimension2,
    DefinitionsAccountDimension3,
    DefinitionsAccountDimension4,
    DefinitionsAccountDimension5,
    DefinitionsAccountDimension6,
    DefinitionsAccountGroup,
    DefinitionsAccountRegulationType,
    DefinitionsAccountRegulationTypeSubgroup,
    DefinitionsAccountSubgroup,
    DefinitionsAccountType,
    DefinitionsActivity,
    DefinitionsActivityBenchmarkType,
    DefinitionsActivityClientLabel1,
    DefinitionsActivityClientLabel2,
    DefinitionsActivityClientLabel3,
    DefinitionsActivityCountryLabel1,
    DefinitionsActivityCountryLabel2,
    DefinitionsActivityCountryLabel3,
    DefinitionsActivityDimension1,
    DefinitionsActivityDimension2,
    DefinitionsActivityDimension3,
    DefinitionsActivityGroup,
    DefinitionsActivitySourceType,
    DefinitionsActivitySubgroup,
    DefinitionsActivityTopgroup,
    DefinitionsActivityType,
    DefinitionsAgeCategory,
    DefinitionsBalanceType,
    DefinitionsBenchmarkSet,
    DefinitionsBenchmarkTask,
    DefinitionsCaregiver,
    DefinitionsCostBenchmarkType,
    DefinitionsCostCentre,
    DefinitionsCostCentreBenchmarkType,
    DefinitionsCostCentreBenchmarkTypeGroup,
    DefinitionsCostCentreBenchmarkTypeSubgroup,
    DefinitionsCostCentreBenchmarkTypeTopgroup,
    DefinitionsCostCentreGroup,
    DefinitionsCostCentreLevel1,
    DefinitionsCostCentreLevel2,
    DefinitionsCostCentreLevel3,
    DefinitionsCostCentreLevel4,
    DefinitionsCostCentreLevel5,
    DefinitionsCostCentreRegulationType,
    DefinitionsCostCentreRegulationTypeSubgroup,
    DefinitionsCostCentreSubgroup,
    DefinitionsCostCentreTopgroup,
    DefinitionsCostCentreType,
    DefinitionsCostClassification,
    DefinitionsCostDriver,
    DefinitionsCostRegulationType,
    DefinitionsCostRegulationTypeLabel1,
    DefinitionsCostRegulationTypeLabel2,
    DefinitionsCostRegulationTypeLabel3,
    DefinitionsDiagnosis,
    DefinitionsDiagnosisLabel1,
    DefinitionsEmployees,
    DefinitionsErrors,
    DefinitionsExclusionGroup,
    DefinitionsFinancialStatementCategory,
    DefinitionsFinancialStatementGroup,
    DefinitionsGender,
    DefinitionsJobTitle,
    DefinitionsJobTitleBenchmarkType,
    DefinitionsJobTitleBenchmarkTypeSubgroup,
    DefinitionsJobTitleBenchmarkTypeTopgroup,
    DefinitionsJobTitleGroup,
    DefinitionsJobTitleRegulationType,
    DefinitionsJobTitleRegulationTypeSubgroup,
    DefinitionsJobTitleType,
    DefinitionsMachine,
    DefinitionsMachineComponent,
    DefinitionsMachineType,
    DefinitionsMaterial,
    DefinitionsMaterialType,
    DefinitionsModelingReleaseType,
    DefinitionsMonth,
    DefinitionsOrganisationLabel1,
    DefinitionsOrganisationLabel2,
    DefinitionsOrganisationLabel3,
    DefinitionsOrganisationLabel4,
    DefinitionsOrganisationLabel5,
    DefinitionsOrganisationLabel6,
    DefinitionsPayer,
    DefinitionsPayerGroup,
    DefinitionsPayerSubgroup,
    DefinitionsPostalCode,
    DefinitionsProcedure,
    DefinitionsProduct,
    DefinitionsProductClientLabel1,
    DefinitionsProductClientLabel2,
    DefinitionsProductClientLabel3,
    DefinitionsProductCountryLabel1,
    DefinitionsProductCountryLabel2,
    DefinitionsProductCountryLabel3,
    DefinitionsProductGroup,
    DefinitionsProductionCharacteristicSource,
    DefinitionsProductionCharacteristicType,
    DefinitionsProductionType,
    DefinitionsProductionUnit,
    DefinitionsProductionUnitBenchmarkType,
    DefinitionsProductionUnitRegulationType,
    DefinitionsProductionUnitRegulationTypeSubgroup,
    DefinitionsProductionUnitType,
    DefinitionsProductSourceType,
    DefinitionsProductSubmissionType,
    DefinitionsProductTopgroup,
    DefinitionsRegulationResource,
    DefinitionsRegulationResourceDefaultSource,
    DefinitionsRegulationResourceGroup,
    DefinitionsRegulationResourceSubgroup,
    DefinitionsRegulationResourceTopgroup,
    DefinitionsRegulationTask,
    DefinitionsRegulationTaskGroup,
    DefinitionsRegulationTaskSubgroup,
    DefinitionsResource,
    DefinitionsResourceAllocationRule,
    DefinitionsResourceAllocationRuleGroup,
    DefinitionsResourceBenchmarkType,
    DefinitionsResourceBenchmarkTypeGroup,
    DefinitionsResourceBenchmarkTypeSubgroup,
    DefinitionsResourceBenchmarkTypeTopgroup,
    DefinitionsResourceTaskToActivityGroupRule,
    DefinitionsResourceTaskToActivityRule,
    DefinitionsResourceTaskTransferGroupRule,
    DefinitionsResourceTaskTransferMethod,
    DefinitionsResourceTaskTransferRule,
    DefinitionsResourceTransferMethod,
    DefinitionsResourceType,
    DefinitionsRoom,
    DefinitionsRuleType,
    DefinitionsScenarioType,
    DefinitionsSector,
    DefinitionsSegment,
    DefinitionsSegmentSubgroup,
    DefinitionsSpecialism,
    DefinitionsSpecialismSourceType,
    DefinitionsStatus,
    DefinitionsSubAccount,
    DefinitionsSubAccountSourceType,
    DefinitionsSubmissionResource,
    DefinitionsSubmissionResourceGroup,
    DefinitionsSubmissionResourceSubgroup,
    DefinitionsSubmissionResourceTopgroup,
    DefinitionsSubmissionSet,
    DefinitionsSubmissionSubtype,
    DefinitionsSubmissionTask,
    DefinitionsSubmissionTaskGroup,
    DefinitionsSubmissionTaskSubgroup,
    DefinitionsSubmissionTaskTopgroup,
    DefinitionsSubmissionType,
    DefinitionsSubProductionUnit,
    DefinitionsSubtask,
    DefinitionsTask,
    DefinitionsTaskType,
    DefinitionsTransferType,
    DefinitionsUnitCostReferenceSource,
    IAppDefinitions,
    SetSubtype,
    SetType,
} from "./app-definitions.types";


export type DefinitionKey = StringKeyOf<IAppDefinitions>;
export { DefinitionDisplayMode };


// ----------------------------------------------------------------------------------
//
function displayCodeFormatter(code: unknown, item: DefinitionDisplayCodeName, displayMode: DefinitionDisplayMode,): string {
    return displayMode === "code" && item != null ? item.displayCode : undefined;
}


// ----------------------------------------------------------------------------------
//
export function isDisplayCodeNameDictionary(definition: _.Dictionary<DefinitionDisplayCodeName | DefinitionCodeName<string>>):
    definition is _.Dictionary<DefinitionDisplayCodeName> {
    let first: DefinitionDisplayCodeName;

    // eslint-disable-next-line guard-for-in
    for (const key in definition) {
        first = definition[key] as DefinitionDisplayCodeName;
        break;
    }

    return first?.displayCode !== undefined;
}


// ----------------------------------------------------------------------------------
//
type GetDictionaryItemType<T> = T extends _.Dictionary<infer U> ? U : never;


// ----------------------------------------------------------------------------------
//
@Injectable({
    providedIn: "root"
})
export class AppDefinitions extends AppServerDefinitionsBase implements IAppDefinitions {
    // ----------------------------------------------------------------------------------
    // Fields
    account = this.def<DefinitionsAccount>(displayCodeFormatter);
    accountAllocationRule = this.def<DefinitionsAccountAllocationRule>();
    accountAllocationRuleGroup = this.def<DefinitionsAccountAllocationRuleGroup>();
    accountBenchmarkType = this.def<DefinitionsAccountBenchmarkType>();
    accountBenchmarkTypeGroup = this.def<DefinitionsAccountBenchmarkTypeGroup>();
    accountBenchmarkTypeSubgroup = this.def<DefinitionsAccountBenchmarkTypeSubgroup>();
    accountBenchmarkTypeTopgroup = this.def<DefinitionsAccountBenchmarkTypeTopgroup>();
    accountDimension1 = this.def<DefinitionsAccountDimension1>();
    accountDimension1RegulationType = this.def<DefinitionsAccountDimension1RegulationType>();
    accountDimension2 = this.def<DefinitionsAccountDimension2>();
    accountDimension3 = this.def<DefinitionsAccountDimension3>();
    accountDimension4 = this.def<DefinitionsAccountDimension4>();
    accountDimension5 = this.def<DefinitionsAccountDimension5>();
    accountDimension6 = this.def<DefinitionsAccountDimension6>();
    accountGroup = this.def<DefinitionsAccountGroup>();
    accountRegulationType = this.def<DefinitionsAccountRegulationType>();
    accountRegulationTypeSubgroup = this.def<DefinitionsAccountRegulationTypeSubgroup>();
    accountSubgroup = this.def<DefinitionsAccountSubgroup>();
    accountType = this.def<DefinitionsAccountType>();
    accountType2 = this.def<DefinitionsAccountType>();
    activity = this.def<DefinitionsActivity>(displayCodeFormatter);
    activityBenchmarkType = this.def<DefinitionsActivityBenchmarkType>();
    activityClientLabel1 = this.def<DefinitionsActivityClientLabel1>();
    activityClientLabel2 = this.def<DefinitionsActivityClientLabel2>();
    activityClientLabel3 = this.def<DefinitionsActivityClientLabel3>();
    activityCountryLabel1 = this.def<DefinitionsActivityCountryLabel1>();
    activityCountryLabel2 = this.def<DefinitionsActivityCountryLabel2>();
    activityCountryLabel3 = this.def<DefinitionsActivityCountryLabel3>();
    activityDimension1 = this.def<DefinitionsActivityDimension1>();
    activityDimension2 = this.def<DefinitionsActivityDimension2>();
    activityDimension3 = this.def<DefinitionsActivityDimension3>();
    activityGroup = this.def<DefinitionsActivityGroup>();
    activitySourceType = this.def<DefinitionsActivitySourceType>();
    activitySubgroup = this.def<DefinitionsActivitySubgroup>();
    activityTopgroup = this.def<DefinitionsActivityTopgroup>();
    activityType = this.def<DefinitionsActivityType>();
    ageCategory = this.def<DefinitionsAgeCategory>();
    balanceType = this.def<DefinitionsBalanceType>();
    benchmarkSet = this.def<DefinitionsBenchmarkSet>();
    benchmarkTask = this.def<DefinitionsBenchmarkTask>();
    caregiver = this.def<DefinitionsCaregiver>();
    costBenchmarkType = this.def<DefinitionsCostBenchmarkType>();
    costCentre = this.def<DefinitionsCostCentre>();
    costCentreBenchmarkType = this.def<DefinitionsCostCentreBenchmarkType>();
    costCentreBenchmarkTypeGroup = this.def<DefinitionsCostCentreBenchmarkTypeGroup>();
    costCentreBenchmarkTypeSubgroup = this.def<DefinitionsCostCentreBenchmarkTypeSubgroup>();
    costCentreBenchmarkTypeTopgroup = this.def<DefinitionsCostCentreBenchmarkTypeTopgroup>();
    costCentreGroup = this.def<DefinitionsCostCentreGroup>();
    costCentreLevel1 = this.def<DefinitionsCostCentreLevel1>();
    costCentreLevel2 = this.def<DefinitionsCostCentreLevel2>();
    costCentreLevel3 = this.def<DefinitionsCostCentreLevel3>();
    costCentreLevel4 = this.def<DefinitionsCostCentreLevel4>();
    costCentreLevel5 = this.def<DefinitionsCostCentreLevel5>();
    costCentreRegulationType = this.def<DefinitionsCostCentreRegulationType>();
    costCentreRegulationTypeSubgroup = this.def<DefinitionsCostCentreRegulationTypeSubgroup>();
    costCentreSubgroup = this.def<DefinitionsCostCentreSubgroup>();
    costCentreTopgroup = this.def<DefinitionsCostCentreTopgroup>();
    costCentreType = this.def<DefinitionsCostCentreType>();
    costClassification = this.def<DefinitionsCostClassification>();
    costDriver = this.def<DefinitionsCostDriver>();
    costRegulationType = this.def<DefinitionsCostRegulationType>();
    costRegulationTypeLabel1 = this.def<DefinitionsCostRegulationTypeLabel1>();
    costRegulationTypeLabel2 = this.def<DefinitionsCostRegulationTypeLabel2>();
    costRegulationTypeLabel3 = this.def<DefinitionsCostRegulationTypeLabel3>();
    diagnosis = this.def<DefinitionsDiagnosis>(displayCodeFormatter);
    diagnosisLabel1 = this.def<DefinitionsDiagnosisLabel1>();
    employee = this.def<DefinitionsEmployees>();
    errors = this.def<DefinitionsErrors>();
    exclusionGroup = this.def<DefinitionsExclusionGroup>();
    financialStatementCategory = this.def<DefinitionsFinancialStatementCategory>();
    financialStatementGroup = this.def<DefinitionsFinancialStatementGroup>();
    gender = this.def<DefinitionsGender>();
    jobTitle = this.def<DefinitionsJobTitle>();
    jobTitleBenchmarkType = this.def<DefinitionsJobTitleBenchmarkType>();
    jobTitleBenchmarkTypeSubgroup = this.def<DefinitionsJobTitleBenchmarkTypeSubgroup>();
    jobTitleBenchmarkTypeTopgroup = this.def<DefinitionsJobTitleBenchmarkTypeTopgroup>();
    jobTitleGroup = this.def<DefinitionsJobTitleGroup>();
    jobTitleRegulationType = this.def<DefinitionsJobTitleRegulationType>();
    jobTitleRegulationTypeSubgroup = this.def<DefinitionsJobTitleRegulationTypeSubgroup>();
    jobTitleType = this.def<DefinitionsJobTitleType>();
    machine = this.def<DefinitionsMachine>();
    machineComponent = this.def<DefinitionsMachineComponent>();
    machineType = this.def<DefinitionsMachineType>();
    material = this.def<DefinitionsMaterial>();
    materialType = this.def<DefinitionsMaterialType>();
    modelingRelease = this.def<DefinitionsModelingReleaseType>();
    month = this.def<DefinitionsMonth>();
    organisationLabel1 = this.def<DefinitionsOrganisationLabel1>();
    organisationLabel2 = this.def<DefinitionsOrganisationLabel2>();
    organisationLabel3 = this.def<DefinitionsOrganisationLabel3>();
    organisationLabel4 = this.def<DefinitionsOrganisationLabel4>();
    organisationLabel5 = this.def<DefinitionsOrganisationLabel5>();
    organisationLabel6 = this.def<DefinitionsOrganisationLabel6>();
    payer = this.def<DefinitionsPayer>(displayCodeFormatter);
    payerGroup = this.def<DefinitionsPayerGroup>();
    payerSubgroup = this.def<DefinitionsPayerSubgroup>();
    postalCode = this.def<DefinitionsPostalCode>();
    procedure = this.def<DefinitionsProcedure>(displayCodeFormatter);
    product = this.def<DefinitionsProduct>(displayCodeFormatter);
    productClientLabel1 = this.def<DefinitionsProductClientLabel1>();
    productClientLabel2 = this.def<DefinitionsProductClientLabel2>();
    productClientLabel3 = this.def<DefinitionsProductClientLabel3>();
    productCountryLabel1 = this.def<DefinitionsProductCountryLabel1>();
    productCountryLabel2 = this.def<DefinitionsProductCountryLabel2>();
    productCountryLabel3 = this.def<DefinitionsProductCountryLabel3>();
    productGroup = this.def<DefinitionsProductGroup>();
    productSourceType = this.def<DefinitionsProductSourceType>();
    productSubmissionType = this.def<DefinitionsProductSubmissionType>();
    productTopgroup = this.def<DefinitionsProductTopgroup>();
    productType = this.def<DefinitionsProductGroup>();
    productionCharacteristicSource = this.def<DefinitionsProductionCharacteristicSource>();
    productionCharacteristicType = this.def<DefinitionsProductionCharacteristicType>();
    productionType = this.def<DefinitionsProductionType>();
    productionUnit = this.def<DefinitionsProductionUnit>();
    productionUnitBenchmarkType = this.def<DefinitionsProductionUnitBenchmarkType>();
    productionUnitRegulationType = this.def<DefinitionsProductionUnitRegulationType>();
    productionUnitRegulationTypeSubgroup = this.def<DefinitionsProductionUnitRegulationTypeSubgroup>();
    productionUnitType = this.def<DefinitionsProductionUnitType>();
    regulationResource = this.def<DefinitionsRegulationResource>();
    regulationResourceDefaultSource = this.def<DefinitionsRegulationResourceDefaultSource>();
    regulationResourceGroup = this.def<DefinitionsRegulationResourceGroup>();
    regulationResourceSubgroup = this.def<DefinitionsRegulationResourceSubgroup>();
    regulationResourceTopgroup = this.def<DefinitionsRegulationResourceTopgroup>();
    regulationTask = this.def<DefinitionsRegulationTask>();
    regulationTaskGroup = this.def<DefinitionsRegulationTaskGroup>();
    regulationTaskSubgroup = this.def<DefinitionsRegulationTaskSubgroup>();
    resource = this.def<DefinitionsResource>();
    resourceAllocationRule = this.def<DefinitionsResourceAllocationRule>();
    resourceAllocationRuleGroup = this.def<DefinitionsResourceAllocationRuleGroup>();
    resourceBenchmarkType = this.def<DefinitionsResourceBenchmarkType>();
    resourceBenchmarkTypeGroup = this.def<DefinitionsResourceBenchmarkTypeGroup>();
    resourceBenchmarkTypeSubgroup = this.def<DefinitionsResourceBenchmarkTypeSubgroup>();
    resourceBenchmarkTypeTopgroup = this.def<DefinitionsResourceBenchmarkTypeTopgroup>();
    resourceTaskToActivityRule = this.def<DefinitionsResourceTaskToActivityRule>(this._ruleNameFormatter.bind(this));
    resourceTaskToActivityRuleGroup = this.def<DefinitionsResourceTaskToActivityGroupRule>(this._ruleNameFormatter.bind(this));
    resourceTaskTransferMethod = this.def<DefinitionsResourceTaskTransferMethod>();
    resourceTaskTransferRule = this.def<DefinitionsResourceTaskTransferRule>(this._ruleNameFormatter.bind(this));
    resourceTaskTransferRuleGroup = this.def<DefinitionsResourceTaskTransferGroupRule>(this._ruleNameFormatter.bind(this));
    resourceTransferMethod = this.def<DefinitionsResourceTransferMethod>();
    resourceType = this.def<DefinitionsResourceType>();
    room = this.def<DefinitionsRoom>();
    ruleType = this.def<DefinitionsRuleType>(this._ruleNameFormatter.bind(this));
    scenarioType = this.def<DefinitionsScenarioType>();
    sector = this.def<DefinitionsSector>();
    segment = this.def<DefinitionsSegment>(displayCodeFormatter);
    segmentSubgroup = this.def<DefinitionsSegmentSubgroup>();
    setSubtype = this.def<SetSubtype>();
    setType = this.def<SetType>();
    specialism = this.def<DefinitionsSpecialism>(displayCodeFormatter);
    specialismSourceType = this.def<DefinitionsSpecialismSourceType>();
    status = this.def<DefinitionsStatus>();
    subAccount = this.def<DefinitionsSubAccount>(displayCodeFormatter);
    subAccountSourceType = this.def<DefinitionsSubAccountSourceType>();
    subProductionUnit = this.def<DefinitionsSubProductionUnit>();
    submissionResource = this.def<DefinitionsSubmissionResource>();
    submissionResourceTopgroup = this.def<DefinitionsSubmissionResourceTopgroup>();
    submissionResourceGroup = this.def<DefinitionsSubmissionResourceGroup>();
    submissionResourceSubgroup = this.def<DefinitionsSubmissionResourceSubgroup>();
    submissionSet = this.def<DefinitionsSubmissionSet>();
    submissionSubtype = this.def<DefinitionsSubmissionSubtype>();
    submissionTask = this.def<DefinitionsSubmissionTask>();
    submissionTaskTopgroup = this.def<DefinitionsSubmissionTaskTopgroup>();
    submissionTaskGroup = this.def<DefinitionsSubmissionTaskGroup>();
    submissionTaskSubgroup = this.def<DefinitionsSubmissionTaskSubgroup>();
    submissionType = this.def<DefinitionsSubmissionType>();
    subtask = this.def<DefinitionsSubtask>(this._taskNameFormatter.bind(this));
    task = this.def<DefinitionsTask>(this._taskNameFormatter.bind(this));
    taskType = this.def<DefinitionsTaskType>();
    transferType = this.def<DefinitionsTransferType>();
    unitCostReferenceSource = this.def<DefinitionsUnitCostReferenceSource>();


    // ----------------------------------------------------------------------------------
    //
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);

        // Init all definition sections
        this.init();
    }


    private _definitionsByDisplayCode: _.Dictionary<_.Dictionary<unknown>> = {};


    getDisplayCode(sectionName: keyof IAppDefinitions, code: string): string {
        return this.getDisplayName(sectionName, code, "code");
    }


    getDefinitionByDisplayCode<TSection extends keyof IAppDefinitions>(
        sectionName: TSection,
        displayCode: string
    ): GetDictionaryItemType<IAppDefinitions[TSection]> {
        if (this._definitionsByDisplayCode[sectionName] == null) {
            const def = this.getSection(sectionName);
            const flatItems = _.values(def.data);
            this._definitionsByDisplayCode[sectionName] = _.keyBy(flatItems, x => x.displayCode);
        }

        return this._definitionsByDisplayCode[sectionName][displayCode] as GetDictionaryItemType<IAppDefinitions[TSection]>;
    }


    clearCache(...sections: Array<keyof IAppDefinitions>): void {
        for (const section of sections) {
            delete this._definitionsByDisplayCode[section];
        }

        super.clearCache(...sections);
    }


    private _ruleNameFormatter(code: unknown, item: unknown, displayMode: DefinitionDisplayMode): string {
        return code == null && displayMode === "name"
            ? this._translate.translate("APP._Definitions.Rule.NoRule")
            : undefined;
    }


    private _taskNameFormatter(code: unknown, item: unknown, displayMode: DefinitionDisplayMode): string {
        return code == null
            ? this._translate.translate("APP._.Empty")
            : undefined;
    }
}
