import ldEach from "lodash-es/each";
import ldCompact from "lodash-es/compact";
import ldAssign from "lodash-es/assign";
import ldMap from "lodash-es/map";
import ldIsEmpty from "lodash-es/isEmpty";
import ldUniq from "lodash-es/uniq";
import ldFilter from "lodash-es/filter";
import ldKeyBy from "lodash-es/keyBy";
import { forkJoin, isObservable, Observable, of } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { inject } from "@angular/core";

import {
    LoadManager,
    STALE_DATA_SERVICE,
} from "@logex/load-manager";
import { StringKeyOf } from "@logex/framework/types";
import { expandPackedRows, retryOnNetworkError } from "@logex/framework/utilities";
import {
    CustomDisplayNameFormatter,
    DefinitionDisplayMode,
    DefinitionPostProcessor,
    DefinitionSection,
    GeneratorDefinitionSection,
    IDefinitionEntry,
    IDefinitions, ItemType,
    LG_APP_SESSION,
    LookupGeneratorDefinitionSection,
    OrderByType,
} from "@logex/framework/lg-application";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";

import { AppSession } from "@shared/types";
import { IAppDefinitions } from "./app-definitions.types";
import { AppDefinitionGateway } from "./app-definition-gateway";


export class AppServerDefinitionsBase implements IDefinitions<IAppDefinitions> {

    private _loadManager = inject(LoadManager, { self: true });
    private _staleDataService = inject(STALE_DATA_SERVICE, { self: true });
    private _gateway = inject(AppDefinitionGateway);
    protected _appSession = inject<AppSession>(LG_APP_SESSION);
    protected _translate: LgTranslateService = inject(LgTranslateService);
    protected _console = inject(LgConsole);

    // ----------------------------------------------------------------------------------
    // Fields
    protected _allSections: Record<string, DefinitionSection> = {};

    // ----------------------------------------------------------------------------------
    //
    protected init(): void {
        const names = Object.keys(this).filter(
            name => (this as any)[name] && !!(this as any)[name].$dummy
        );

        // Collect all available sections
        this._allSections = names.reduce(
            (a, name) => {
                a[name] = {
                    name,
                    isLoaded: false,
                    customDisplayNameFormatter: (this as any)[name].customDisplayNameFormatter,
                    postProcessor: (this as any)[name].postProcessor,
                    generatorOptions: (this as any)[name].generatorOptions,
                    generateLookup: (this as any)[name].generateLookup
                };
                return a;
            },
            {} as Record<string, DefinitionSection>
        );

        // Generate getters that warns if requested definition is not loaded yet and create loader
        ldEach(this._allSections, section => {
            Object.defineProperty(this, section.name, {
                get: () => {
                    if (section.data == null) {
                        this._console.error(`Definition "${section.name}" is not loaded`);
                    }
                    return section.data;
                },
                set: () => {
                    throw Error("Definitions are read-only.");
                }
            });
            // Add stale data loader for definition
            this._addLoader(section.name as StringKeyOf<IAppDefinitions>);
        });
    }


    // ----------------------------------------------------------------------------------
    //
    protected _addLoader(sectionName: StringKeyOf<IAppDefinitions>) {
        this._loadManager.add({
            ...this._staleDataService.configureLoader(
                sectionName,
                {
                    args: [{
                        clientId: () => this._appSession.clientId,
                        scenarioId: () => this._appSession.scenarioId,
                        name: () => sectionName
                    }],
                    loader: (args, subscription) => this._gateway
                        .selectDefinition(args, subscription)
                        .pipe(retryOnNetworkError()),
                },
                (sectionInfo, isStale) => {
                    const section = this.getSection(sectionName);

                    section.codeField = sectionInfo.codeField;
                    section.codeType = sectionInfo.codeType;
                    section.nameField = sectionInfo.nameField;
                    section.orderByField = sectionInfo.orderByField;
                    section.displayMode = sectionInfo.displayMode;

                    const entryClass = makeDefinitionEntryClass(this, section);
                    section.isLoaded = true;
                    if (sectionInfo.fallbackValue) {
                        section.fallbackValue = new entryClass(sectionInfo.fallbackValue);
                    }
                    let data: Record<string, any>;
                    if (sectionInfo.rows?.length !== 0) {
                        let expanded = expandPackedRows(sectionInfo.rows);
                        if (section.postProcessor) expanded = section.postProcessor(expanded);
                        const wrapped = ldMap(expanded, x => new entryClass(x));
                        data = ldKeyBy(wrapped, section.codeField);
                    } else {
                        data = {};
                    }
                    section.data = data;
                }),

        })
    }


    // ----------------------------------------------------------------------------------
    //
    reload(...requiredDefinitions: Array<StringKeyOf<IAppDefinitions>>): Observable<void> {
        const sectionsToLoad = !ldIsEmpty(requiredDefinitions)
            ? ldUniq(requiredDefinitions as string[])
            : ldFilter(Object.keys(this._allSections), (x: StringKeyOf<IAppDefinitions>) =>
                this.isLoaded(x)
            );

        return forkJoin(
            ldCompact(ldMap(sectionsToLoad, (section: string) => this._loadManager.reload(section)))
        ).pipe(map(() => undefined));
    }


    // ----------------------------------------------------------------------------------
    //
    protected _doLoad(sectionName: StringKeyOf<IAppDefinitions>): Observable<Record<string, any>> {
        const section = this.getSection(sectionName);

        if (section.generatorOptions) {
            return this._generateSection(section).pipe(
                map(sourceData => {
                    section.codeField = section.generatorOptions?.codeField;
                    section.codeType = section.generatorOptions?.codeType;
                    section.nameField = section.generatorOptions?.nameField;
                    section.orderByField = section.generatorOptions?.orderByField;
                    section.displayMode = section.generatorOptions?.displayMode;
                    const entryClass = makeDefinitionEntryClass(this, section);

                    if (section.generateLookup) {
                        let data: Record<string, any>;

                        if (sourceData.length !== 0) {
                            const wrapped = ldMap(sourceData, x => new entryClass(x));
                            data = ldKeyBy(wrapped, section.codeField);
                        } else {
                            data = {};
                        }
                        section.data = data;
                        if (section.generatorOptions?.fallbackValue) {
                            section.fallbackValue = new entryClass(
                                section.generatorOptions.fallbackValue
                            );
                        }
                    } else {
                        section.data = sourceData;
                        // without generateLookup we take data as they come
                        if (section.generatorOptions?.fallbackValue)
                            section.fallbackValue = section.generatorOptions.fallbackValue;
                    }

                    section.isLoaded = true;
                    // section.observable = null;

                    return section.data ?? {};
                }),
                shareReplay(1)
            );
        }

        return this._loadSection(section);
    }

    // ----------------------------------------------------------------------------------
    //

    protected _loadSection(section: DefinitionSection) {
        return this._loadManager.load(section.name).pipe(map(data => data[0]));
    }

    // ----------------------------------------------------------------------------------
    //
    protected def<TItem>(
        customDisplayNameFormatter?: CustomDisplayNameFormatter<any, TItem>,
        postProcessor?: DefinitionPostProcessor<TItem>
    ): Record<string, TItem> {
        return { $dummy: true, customDisplayNameFormatter, postProcessor } as any;
    }

    // ----------------------------------------------------------------------------------
    //
    protected generate<TItem, TResult>(
        options: GeneratorDefinitionSection<TItem, TResult>
    ): TResult {
        return { $dummy: true, generatorOptions: options } as any;
    }

    // ----------------------------------------------------------------------------------
    //
    protected generateLookup<TItem>(
        options: LookupGeneratorDefinitionSection<TItem>
    ): Record<string, TItem> {
        return { $dummy: true, generatorOptions: options, generateLookup: true } as any;
    }


    // ----------------------------------------------------------------------------------
    //
    load(...requiredDefinitions: Array<StringKeyOf<IAppDefinitions>>): Observable<any> {
        let sectionsToLoad: Array<StringKeyOf<IAppDefinitions>>;
        if (!ldIsEmpty(requiredDefinitions)) {
            sectionsToLoad = ldUniq(requiredDefinitions);
        } else {
            return of(undefined);
        }

        return forkJoin(
            ldCompact(
                sectionsToLoad.map((section: StringKeyOf<IAppDefinitions>) => {
                    if (this.isLoaded(section)) {
                        return of(this._cached(section));
                    }
                    return this._doLoad(section);
                })
            )
        ).pipe(map(() => undefined));
    }


    // ----------------------------------------------------------------------------------
    //
    getSection(sectionName: StringKeyOf<IAppDefinitions>, checkIsLoaded = false): DefinitionSection {
        const section = this._allSections[sectionName as string];

        if (section == null) {
            throw new Error(`AppDefinitions: Unknown definition ${sectionName} have been required`);
        }

        if (checkIsLoaded && !section.isLoaded) {
            throw new Error(`Definition "${section.name}" is not loaded`);
        }
        return section;
    }

    // ----------------------------------------------------------------------------------
    //
    protected _generateSection(section: DefinitionSection): Observable<any> {
        const options = section.generatorOptions!;
        let requirement: Observable<void>;
        if (options.dependsOn) {
            requirement = this.load(...(options.dependsOn as any[]));
        } else {
            requirement = of(undefined);
        }

        return requirement.pipe(
            switchMap(() => {
                const result = options.generator();
                if (!isObservable(result)) return of(result);
                return result;
            })
        );
    }

    // ----------------------------------------------------------------------------------
    //
    protected _cached<TField extends StringKeyOf<IAppDefinitions>>(
        sectionName: TField
    ): IAppDefinitions[TField] {
        return this.getSection(sectionName).data as any;
    }

    // ----------------------------------------------------------------------------------
    //
    clearCache(...sections: Array<keyof IAppDefinitions>): void {
        // todo: fix typing
        const sectionsToClear = !ldIsEmpty(sections)
            ? ldUniq(sections)
            : (Object.keys(this._allSections) as any as Array<keyof IAppDefinitions>);

        sectionsToClear.forEach(x => {
            this._loadManager.clearArgs(x);
            this._allSections[x as string].isLoaded = false;
        });
    }

    // ----------------------------------------------------------------------------------
    //
    isLoaded<TField extends StringKeyOf<IAppDefinitions>>(name: TField): boolean {
        return this.getSection(name).isLoaded;
    }

    // ----------------------------------------------------------------------------------
    //
    getEntry<TField extends StringKeyOf<IAppDefinitions>>(
        sectionName: TField,
        code: any
    ): ItemType<IAppDefinitions, TField> {
        const section = this.getSection(sectionName, true);
        return this._getEntry(section, code);
    }

    private _getEntry(section: DefinitionSection, code: any): any {
        let item = section.data && section.data[code];

        if (item === undefined && section.fallbackValue !== undefined) {
            item = section.fallbackValue;
        }

        return item;
    }

    // ----------------------------------------------------------------------------------
    //
    getDisplayName(
        sectionName: StringKeyOf<IAppDefinitions>,
        code: any,
        displayMode?: DefinitionDisplayMode
    ): string {
        const item: any = this.getEntry(sectionName, code);

        if (item !== undefined) {
            return item.getDisplayName(displayMode);
        }

        // If definition item is not found, show it as "Unknown"
        const section = this.getSection(sectionName);
        return getDisplayNameImplementation(section, code, null, displayMode, () =>
            this._translate.translate("FW.UNKNOWN")
        );
    }

    // ----------------------------------------------------------------------------------
    //
    getOrderBy<TField extends StringKeyOf<IAppDefinitions>>(
        sectionName: TField,
        code: any
    ): OrderByType<IAppDefinitions, TField> | null {
        if (code === null) {
            return null;
        }

        const item = this.getEntry(sectionName, code);

        if (item !== undefined) {
            const value = (item as DefinitionEntryBase<any>).orderBy;

            if (value !== undefined) {
                return value;
            }
        }

        return null;
    }

    // ----------------------------------------------------------------------------------
    //
    getFallbackValue<TField extends StringKeyOf<IAppDefinitions>>(
        sectionName: TField
    ): ItemType<IAppDefinitions, TField> {
        const section = this.getSection(sectionName);

        if (!section.isLoaded) {
            throw new Error(`Definition "${sectionName}" is not loaded`);
        }

        return section.fallbackValue;
    }
}

abstract class DefinitionEntryBase<IAppDefinitions> implements IDefinitionEntry {
    private _displayName: string | null = null;
    private _orderBy: any;

    // ----------------------------------------------------------------------------------
    //
    protected abstract _getDefinitions(): AppServerDefinitionsBase;

    // ----------------------------------------------------------------------------------
    //
    protected abstract _getDefinitionSection(): DefinitionSection;

    // ----------------------------------------------------------------------------------
    //
    get displayName(): string {
        if (this._displayName === null) {
            this._displayName = this.getDisplayName();
        }

        return this._displayName;
    }

    // ----------------------------------------------------------------------------------
    //
    get orderBy(): any {
        if (this._orderBy == null) {
            const section = this._getDefinitionSection();
            this._orderBy = (this as any)[section.orderByField ?? ""];
        }

        return this._orderBy;
    }

    // ----------------------------------------------------------------------------------
    //
    getDisplayName(displayMode?: DefinitionDisplayMode): string {
        const section = this._getDefinitionSection();
        return getDisplayNameImplementation(
            section,
            (this as any)[section.codeField ?? ""],
            this,
            displayMode,
            () => (this as any)[section.nameField ?? ""]
        );
    }
}

// ----------------------------------------------------------------------------------
//
type Constructor<T = {}> = new (...args: any[]) => T;

export function makeDefinitionEntryClass<TDefinitions, TEntry>(
    definitions: AppServerDefinitionsBase,
    section: DefinitionSection
): Constructor<TEntry & IDefinitionEntry> {
    class DefinitionEntry extends DefinitionEntryBase<TDefinitions> implements IDefinitionEntry {
        constructor(entry: TEntry) {
            super();
            ldAssign(this, entry);
        }

        protected _getDefinitions(): AppServerDefinitionsBase {
            return definitions;
        }

        protected _getDefinitionSection(): DefinitionSection {
            return section;
        }
    }

    return DefinitionEntry as any as Constructor<TEntry & IDefinitionEntry>;
}

export function getDisplayNameImplementation(
    section: DefinitionSection,
    code: any,
    item: any,
    displayMode: DefinitionDisplayMode | undefined,
    nameFn: () => string
): string {
    if (displayMode == null) displayMode = section.displayMode ?? "codeAndName";

    if (section.customDisplayNameFormatter != null) {
        const result = section.customDisplayNameFormatter(code, item, displayMode);
        if (result != null) {
            return result;
        }
    }

    if (code === null) {
        return "-";
    }

    switch (displayMode) {
        case "code":
            return code?.toString() ?? "-";

        case "name":
            return nameFn();

        case "codeAndName":
            return `${getDisplayNameImplementation(
                section,
                code,
                item,
                "code",
                nameFn
            )} - ${getDisplayNameImplementation(section, code, item, "name", nameFn)}`;

        default:
            throw new Error(
                `Display mode "${displayMode}" is not supported by definition "${section.name}"`
            );
    }
}